import React from 'react';
import { Dropdown, Input, Select, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { mapToOptions } from '../../utils/React';
import { StatusNode, Transition } from '../../types';
import CheckBoxBool from '../common/fields/CheckBoxBool';

interface StatusNodeTransitionListRowProps {
    transition: Transition;
    onChange: (
        event: React.SyntheticEvent,
        data: { name: string; value: any },
    ) => void;
    onRemove: (event: React.SyntheticEvent) => void;
    statusNode: StatusNode;
    statusNodes: StatusNode[];
    transitionConditionTypes: Array<{ id: string; label: string }>;
    transitionPostFunctionTypes: Array<{ id: string; label: string }>;
}

const StatusNodeTransitionListRow = ({
    transition,
    onChange,
    onRemove,
    statusNode,
    statusNodes,
    transitionConditionTypes,
    transitionPostFunctionTypes,
}: StatusNodeTransitionListRowProps) => {
    const conditionValues = transition.conditions
        ? transition.conditions.map((c) => c.id)
        : [];
    const postFunctionValues = transition.postFunctionTypes
        ? transition.postFunctionTypes.map((pf) => pf.id)
        : [];

    function conditionsOnChange(event: React.SyntheticEvent, data: any) {
        const value = data.value.map((v: string) => ({
            id: v,
        }));
        onChange(event, { ...data, value });
    }

    function postFunctionsOnChange(event: React.SyntheticEvent, data: any) {
        const value = data.value.map((v: string) => ({
            id: v,
        }));
        onChange(event, { ...data, value });
    }

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell>
                    <Input
                        value={transition.label || ''}
                        name="label"
                        onChange={onChange}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Select
                        name="toStatusNodeUid"
                        onChange={onChange}
                        value={transition.toStatusNodeUid}
                        options={statusNodes
                            .filter((sn) => sn.uid !== statusNode.uid)
                            .map((node) => ({
                                key: node.uid,
                                value: node.uid,
                                text: node.label,
                            }))}
                    />
                </Table.Cell>
                <Table.Cell>
                    <CheckBoxBool
                        label="Hidden"
                        name="isHidden"
                        onChange={onChange}
                        value={transition.isHidden}
                    />
                </Table.Cell>
                <Table.Cell>
                    <ActionButtons removeClicked={onRemove} />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell colSpan={3}>
                    <Dropdown
                        placeholder="Transition Conditions"
                        fluid
                        value={conditionValues}
                        multiple
                        selection
                        options={mapToOptions(transitionConditionTypes)}
                        onChange={conditionsOnChange}
                        name="conditions"
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell colSpan={3}>
                    <Dropdown
                        placeholder="Transition Post Function"
                        fluid
                        value={postFunctionValues}
                        multiple
                        selection
                        options={mapToOptions(transitionPostFunctionTypes)}
                        onChange={postFunctionsOnChange}
                        name="postFunctionTypes"
                    />
                </Table.Cell>
            </Table.Row>
        </React.Fragment>
    );
};

export default WorkflowWrapContext(StatusNodeTransitionListRow);
