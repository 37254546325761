import * as types from '../constants/actionTypes';
import constantApi from '../api/constantApi';
import UtilsApi from '../api/utilsApi';

export function loadModelStructure() {
    return function (dispatch) {
        return UtilsApi.getModelStructure().then((data) => {
            dispatch({
                type: types.CONSTANTS_LOAD_SUCCESS_MODEL_STRUCTURE,
                data,
            });
        });
    };
}

export function loadThresholds() {
    return function (dispatch) {
        return constantApi
            .getAvailableRiskThresholds()
            .then((riskThresholds) => {
                return dispatch({
                    type: `${types.LOAD_AVAILABLE_RISK_THRESHOLDS_SUCCESS}`,
                    riskThresholds,
                });
            });
    };
}

export function saveAdminThreshold(threshold, thresholdId = null) {
    return function (dispatch) {
        return constantApi
            .saveAdminThreshold(threshold, thresholdId)
            .then((threshold) => {
                return dispatch({
                    type: `${types.UPDATE_ADMIN_THRESHOLD_SUCCESS}`,
                    threshold,
                });
            });
    };
}

export function removeAdminThreshold(thresholdId) {
    return function (dispatch) {
        return constantApi.removeAdminThreshold(thresholdId).then(() => {
            return dispatch({
                type: `${types.REMOVE_ADMIN_THRESHOLD_SUCCESS}`,
                thresholdId,
            });
        });
    };
}

export function loadConfig() {
    return function (dispatch) {
        return UtilsApi.getConfig().then((config) => {
            window.featureFlags = config.options.featureFlags;
            return dispatch({
                type: `${types.LOAD_CONFIG_SUCCESS}`,
                config,
            });
        });
    };
}
