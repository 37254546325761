const status = {
    name: {
        required: true,
    },
    statusTypeId: {
        required: { error: 'Please select a status type' },
    },
};
const statusNodeTransitions = {
    label: {
        required: true,
    },
    toStatusNodeUid: {
        required: { error: 'Please choose an endpoint for transition' },
    },
};
const statusNode = {
    label: {
        required: true,
    },
    transitions: {
        validateObjects: {
            schema: statusNodeTransitions,
        },
    },
};

const workflow = {
    label: {
        required: true,
    },
    statuses: {
        length: {
            gt: 1,
            error: 'Please supply at least 1 Status',
        },
        validateObjects: {
            schema: status,
        },
    },
    statusNodes: {
        length: {
            gt: 1,
            error: 'Please supply at least 1 StatusNode',
        },
        validateObjects: {
            schema: statusNode,
        },
    },
};

const field = {
    label: {
        required: false,
    },
};
const activity = {
    label: {
        required: true,
    },
    structuralNodeId: {
        required: {
            error: 'Please supply a group',
        },
    },
    activityStatusId: {
        required: {
            error: 'Please supply a status',
        },
    },
    // fields: {
    //   length: {
    //     gt: 1,
    //     error: 'Please supply at least 1 field'
    //   },
    //   validateObjects: {
    //     schema: field
    //   }
    // }
};

const activitySet = {
    label: {
        required: true,
    },
    activities: {
        length: {
            gt: 1,
            error: 'Please add at least 1 Question',
        },
    },
};
const programmeCategory = {
    label: {
        required: true,
    },
};

const programme = {
    label: {
        required: true,
    },
    activitySets: {
        length: {
            gt: 1,
            error: 'Please supply at least 1 Question Set',
        },
        validateObjects: {
            schema: activitySet,
        },
    },
    categories: {
        validateObjects: {
            schema: programmeCategory,
        },
    },
};

const createPartyUser = {
    firstName: {
        required: true,
    },
    lastName: {
        required: true,
    },
    email: {
        email: true,
    },
    partyId: {
        required: true,
    },
};

const createUserGroup = {
    label: {
        required: { error: 'Please supply the label' },
    },
};

const createParty = {
    label: {
        required: { error: 'Please supply the label' },
    },
    ref: {
        required: { error: 'Please supply a party reference' },
    },
    structuralNodeId: {
        required: { error: 'Please supply the group' },
    },
};

const addUserToUserGroup = {
    userId: {
        required: { error: 'Please select a user' },
    },
};

const saveAddressToParty = {
    line1: {
        required: { error: 'Please supply the Line 1' },
    },
    city: {
        required: { error: 'Please supply the City' },
    },
    zipOrPostcode: {
        required: { error: 'Please supply the Postcode' },
    },
    countryId: {
        required: true,
    },
};

const createPermission = {
    name: {
        required: { error: 'Please supply the name' },
    },
    typeId: {
        required: { error: 'Please supply the node type' },
    },
};

const scheduleExecution = {
    startDate: {
        required: { error: 'Please supply the launch date' },
    },
};

const updatePasswordData = {
    email: {
        email: true,
        required: { error: 'Please supply the email' },
    },
    newPassword: {
        password: true,
        required: { error: 'Please supply the new password' },
    },
    confirmPassword: {
        equal: { compareField: 'newPassword', error: 'Passwords do not match' },
    },
    token: {
        required: { error: 'Please supply the token' },
    },
};

const changePasswordData = {
    oldPassword: {
        required: { error: 'Please supply the current password' },
    },
    newPassword: {
        password: true,
        required: { error: 'Please supply the new password' },
    },
    confirmPassword: {
        equal: { compareField: 'newPassword', error: 'Passwords do not match' },
    },
};

const requestResetPassword = {
    email: {
        required: {
            error: 'Please enter an email address to reset your password.',
        },
    },
};

const loginCredentials = {
    email: {
        email: true,
        required: { error: 'Please supply the email' },
    },
    password: {
        required: { error: 'Please supply the password' },
    },
};

const entity = {
    label: {
        required: { error: 'Please supply the label' },
    },
    /*    executionPlanId: {
        required: { error: 'Please select a programme plan' },
        if: {
            launchExecution: true,
        },
    },*/
    distributorId: {
        required: { error: 'Please select a distributor' },
        if: {
            coManufactured: true,
        },
    },
};

const governanceProgramme = {
    executionPlanId: {
        required: { error: 'Please select a programme plan' },
    },
    applicableDate: {
        required: { error: 'Please select a applicable year' },
    },
};

const apiKeyCreation = {
    label: {
        required: { error: 'Please enter a label' },
    },
    party: {
        required: { error: 'Please select a party' },
    },
    apiKeyType: {
        required: { error: 'Please select an api key type' },
    },
    userId: {
        required: { error: 'Please select an user' },
        if: {
            apiKeyType: 'function',
        },
    },
    dataAccessCodes: {
        required: { error: 'Please select permissions to invoke functions' },
        if: {
            apiKeyType: 'function',
        },
    },
};

const apiKeyUpdate = {
    label: {
        required: { error: 'Label cant be empty' },
    },
};

export default {
    activity,
    programme,
    activitySet,
    workflow,
    createPartyUser,
    createUserGroup,
    createParty,
    addUserToUserGroup,
    saveAddressToParty,
    createPermission,
    scheduleExecution,
    updatePasswordData,
    changePasswordData,
    loginCredentials,
    requestResetPassword,
    entity,
    governanceProgramme,
    apiKeyCreation,
    apiKeyUpdate,
};
