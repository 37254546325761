import * as _ from 'lodash';

export function applyResolutionLabelOverride(status, labelOverrides) {
    if (labelOverrides && labelOverrides[status.key]) {
        const result = status.resolutions
            .map((resolution) => {
                return {
                    ...resolution,
                    labelOverride:
                        _.get(
                            labelOverrides[status.key],
                            resolution.activityResolutionTypeId +
                                '.labelOverride',
                        ) || resolution.labelOverride,
                };
            })
            .filter((resolution) => {
                const available = _.get(
                    labelOverrides[status.key],
                    resolution.activityResolutionTypeId + '.available',
                );
                return _.isUndefined(available) ? true : available;
            });
        return result;
    }

    return status.resolutions;
}

export default applyResolutionLabelOverride;
