import { Icon, Label, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Entity } from '../../../../../types';
import { useMemo, useState } from 'react';
import { orderBy, uniqBy } from 'lodash';

import './FairValueRAGMatrix.scss';
import { useDispatch } from 'react-redux';
import { SIDEBAR_COMPONENTS } from '../../../../../components/common/SidebarRoot';
import { RAGStatusResult, Result } from '../../../../../api/statusApi';
import * as uiActions from '../../../../../actions/uiActions';
import { RAGStatusIcon } from '../../status/RAGStatusIcon';

interface Props {
    data: {
        data: {
            main: RAGStatusResult[];
        };
    };
}

const sortDirectMap: { [key: string]: 'ascending' | 'descending' } = {
    asc: 'ascending',
    desc: 'descending',
};

const FairValueRAGMatrix = (props: Props) => {
    return null;
    const dispatch = useDispatch();

    const [sortColumn, setSortColumn] = useState('label');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const allResults = props.data.data.main;

    const prod4Statuses = useMemo(() => {
        const results = allResults?.[0].results;
        if (!results) return [];
        return orderBy(results, 'sort').map((result) => ({
            label: result.meta?.subcategory,
            description: result.label,
        }));
    }, [allResults]);

    const rows = useMemo(() => {
        type Return = RAGStatusResult & {
            productClass: string;
            productUMR: string;
            productLabel: string;
            executionId: number;
        };
        const result: Return[] = allResults?.map((result) => {
            const { entries, label, results } = result;
            const productClass = entries?.find(
                (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS',
            )?.value;
            const productUMR = entries?.find(
                (entry) => entry.entityTypeFieldId === 'PRODUCT_REF',
            )?.value;
            const executionId = result.results[0]?.statusResults
                .map((statusResult) => statusResult.relevantEntries)
                .flat()
                .find((entry) => !!entry.executionEntityId)?.executionId;

            return {
                //   negativeResults,
                ...result,
                productClass,
                productUMR,
                executionId,
                productLabel: label,
            };
        });
        return result;
    }, [allResults]);

    const handleChangeSort = (newSortColumn: string) => {
        if (newSortColumn === sortColumn)
            return setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setSortColumn(newSortColumn);
        setSortDirection('asc');
    };

    const handleShowTickets = (result: Result) => {
        const relevantEntries = uniqBy(
            result.statusResults
                .map((statusResult) => statusResult.relevantEntries)
                .flat()
                .map((statusResult) => {
                    return {
                        ...statusResult,
                        field: {
                            id: statusResult.fieldId,
                            label: statusResult.label,
                            activityFieldTypeId:
                                statusResult.activityFieldTypeId,
                            activityFieldType: statusResult.activityFieldTypeId,
                            options: null,
                        },
                    };
                }),
            'id',
        );

        const exceptionIds = result.statusResults
            .filter((statusResult) =>
                !Array.isArray(statusResult.logicResult)
                    ? statusResult.logicResult.result === 'red'
                    : false,
            )
            .map((statusResult) => statusResult.relevantEntries)
            .flat()
            .map((entry) => entry.executionTicketId);
        const ticketIds = relevantEntries.map(
            (entry) => entry.executionTicketId,
        );
        dispatch(
            uiActions.showSidebar({
                sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
                sidebarProps: {
                    baseConfig: {
                        model: 'ExecutionTicket',
                        endpoint: '',
                        params: {
                            filter: {
                                limit: 10,

                                where: { id: { inq: ticketIds } },
                            },
                        },
                        relevantEntries,
                        exceptionIds,
                    },
                    data: {},
                },
                sidebarConfig: { width: 'very wide' },
            }),
        );
    };

    if (!allResults) return null;

    const productRowsSorted = orderBy(
        allResults,
        [sortColumn],
        [sortDirection],
    );

    //   return <pre>{JSON.stringify(productRowsSorted, null, "  ")}</pre>;

    return (
        <Table striped celled compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                        sorted={
                            sortColumn === 'label'
                                ? sortDirectMap[sortDirection]
                                : null
                        }
                        onClick={() => handleChangeSort('label')}
                    ></Table.HeaderCell>
                    {prod4Statuses.map((status) => (
                        <Table.HeaderCell
                            collapsing
                            className="rag-status-matrix-header-cell"
                        >
                            <Popup
                                trigger={<span>{status.label}</span>}
                                content={<div>{status.description}</div>}
                                position="bottom center"
                            />
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows.map(
                    (
                        {
                            label,
                            // relevantEntries,
                            // exceptionEntries,
                            // execution,
                            // resultMap
                            executionId,
                            results,
                        },
                        index,
                    ) => {
                        const sortedResults = orderBy(results, 'sort');
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    <Link
                                        to={`/execution/${executionId}/reports/FF58AC90-821C-11ED-9746-4740A31726A1`}
                                    >
                                        {label}
                                    </Link>
                                </Table.Cell>
                                {sortedResults.map((status) => {
                                    return (
                                        <Table.Cell className="rag-status-matrix-result-cell">
                                            <span
                                                onClick={() =>
                                                    handleShowTickets(status)
                                                }
                                            >
                                                <RAGStatusIcon
                                                    status={status}
                                                />
                                            </span>
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    },
                )}
            </Table.Body>
        </Table>
    );
};

export default FairValueRAGMatrix;
