import React, { useEffect, useState } from 'react';
import { Form, Popup } from 'semantic-ui-react';
import PrincipalSearch from '../PrincipalSearch';
import PrincipalLabel from '../PrincipalLabel';
import userApi from '../../../api/userApi';
import FieldToolTip from './FieldToolTip';

interface PrincipalFieldProps {
    name?: string;
    label?: string;
    value?: string;
    description?: string;
    onChange?: (
        event: React.SyntheticEvent<HTMLElement>,
        data: { name: string; value: string | undefined },
    ) => void;
    disabled?: boolean;
    errors?: object;
    required?: boolean;
    editable?: boolean;
    error?: boolean;
}

interface Principal {
    id: string;
    label: string;
    type: string;
}

const PrincipalField: React.FC<PrincipalFieldProps> = ({
    name,
    label,
    value,
    onChange,
    disabled = false,
    description,
    errors,
    required,
    editable = true,
    error,
}) => {
    const [currentPrincipal, setCurrentPrincipal] = useState<Principal | null>(
        null,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);

    useEffect(() => {
        const loadPrincipal = async () => {
            if (value) {
                setIsLoading(true);
                try {
                    const principal = await userApi.getPrincipal(value);
                    setCurrentPrincipal(principal);
                } catch (error) {
                    console.error('Failed to load principal:', error);
                }
                setIsLoading(false);
            } else {
                setCurrentPrincipal(null);
            }
        };

        loadPrincipal();
    }, [value]);

    const handleChange = (
        event: React.SyntheticEvent,
        data: { value: Principal | null },
    ) => {
        if (onChange) {
            onChange(event as React.SyntheticEvent<HTMLElement>, {
                name: name || '',
                value: data.value?.id,
            });
        }
        setShowSearch(false);
    };

    const handleLabelClick = () => {
        if (!disabled && editable) {
            setShowSearch(true);
        }
    };

    if (!editable) {
        return <>{currentPrincipal?.label || ''}</>;
    }

    return (
        <Form.Field required={required} error={error}>
            <label>
                {label}
                <FieldToolTip description={description} />
            </label>
            <Popup
                on="click"
                position="bottom left"
                trigger={
                    <div>
                        <PrincipalLabel
                            principal={currentPrincipal}
                            onClick={handleLabelClick}
                        />
                    </div>
                }
                open={showSearch}
                onClose={() => setShowSearch(false)}
            >
                <div style={{ width: '250px' }}>
                    <PrincipalSearch
                        onChange={handleChange}
                        saving={isLoading}
                        addUnassignValue={true}
                        currentPrincipal={currentPrincipal}
                    />
                </div>
            </Popup>
        </Form.Field>
    );
};

export default PrincipalField;
