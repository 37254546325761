import React from 'react';
import Moment from 'react-moment';
import { User } from '../../types';
import { useGetFeatures } from '../../utils/Features';

import './FormFieldAuthor.scss';

type FieldAuthorProps = {
    user: Pick<User, 'firstName' | 'lastName'>;
    modifiedDate: Date;
    dateFormat?: string;
};

export const FieldAuthor = ({
    user,
    modifiedDate,
    dateFormat,
}: FieldAuthorProps) => {
    const { HideAuthor } = useGetFeatures();
    return (
        !HideAuthor && (
            <div className="form-field-author">
                {user.firstName} {user.lastName} -{' '}
                <Moment date={modifiedDate} format={dateFormat} />
            </div>
        )
    );
};
