import { SerializedError } from '@reduxjs/toolkit';
import { ApiError } from '@vidende/store/api/base';
import { Container, Message } from 'semantic-ui-react';

interface ErrorRendererProps {
    error: ApiError | SerializedError;
}
export const ErrorRenderer = ({ error }: ErrorRendererProps) => {
    let message = 'An error occurred.';
    if (error && 'message' in error && error.message) {
        message = error.message;
    }

    if (error && 'status' in error) {
        switch (error.status) {
            case 401:
                message = 'You are not authorized to perform this action.';
                break;
            case 403:
                message = 'You are not authorized to perform this action.';
                break;
            case 404:
                message = 'The requested resource was not found.';
                break;
            case 500:
                message = 'An error occurred.';
                break;
            default:
                message = 'An error occurred.';
                break;
        }
    }

    return (
        <Container>
            <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{message}</p>
            </Message>
        </Container>
    );
};
