import * as types from '../constants/actionTypes';

export default function userPermissionsReducer(
    state = { permissions: {}, permissionsByType: {}, permissionsByNode: {} },
    action,
) {
    switch (action.type) {
        case `${types.USER_LOGIN_SUCCESS}_CURRENT`: {
            const { response } = action;
            const { permissions, permissionsByType, nodes } = response;
            const permissionsByNode = {};
            return {
                ...state,
                permissions,
                permissionsByType,
                permissionsByNode,
                nodes,
            };
        }
        case `${types.USER_LOGOUT}_CURRENT`: {
            return {};
        }
        case `${types.LOAD_PERMISSIONS_FOR_NODE_SUCCESS}_CURRENT`: {
            const { nodeId, permissions } = action;
            const newState = {
                ...state,
                permissionsByNode: {
                    ...state.permissionsByNode,
                    [nodeId]: permissions,
                },
            };
            return newState;
        }

        default:
            return state;
    }
}
