import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import * as _ from 'lodash';

import UIDGenerator from '../../../utils/UIDGenerator';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';
import FieldDescriptionView from './FieldDescriptionView';
import { mapToOptions } from '../../../utils/React';

const RadioGroup = (props) => {
    const {
        label,
        options,
        value,
        name,
        onChange,
        disabled = false,
        required,
        editable = true,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
        valueField,
        labelField,
        handleOpenSideBar,
    } = props;
    const u = UIDGenerator.get();

    const displayValue =
        Array.isArray(options) &&
        options.find((option) => option.value == value)?.label;

    if (!editable) {
        return <React.Fragment>{displayValue}</React.Fragment>;
    }

    const optionsHaveDescription =
        Array.isArray(options) && options?.some((option) => option.description);

    return (
        <Form.Field required={required} error={error}>
            <label>
                {label}
                {!props?.descriptionOnScreen && <FieldToolTip {...props} />}
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
                {handleOpenSideBar && optionsHaveDescription && (
                    <FieldDescriptionView
                        handleOpenSideBar={handleOpenSideBar}
                    />
                )}
            </label>
            {props?.descriptionOnScreen && props?.description && (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {props.description}
                </div>
            )}
            {_.map(
                mapToOptions(options, labelField, false, valueField),
                (option) => (
                    <Form.Radio
                        key={option.key}
                        label={option.text}
                        name={`radioGroup${u}`}
                        value={option.value}
                        checked={value === option.value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                ),
            )}
        </Form.Field>
    );
};

RadioGroup.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
};

RadioGroup.defaultProps = {
    value: '',
};

export default RadioGroup;
