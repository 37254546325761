import React from 'react';
import * as PropTypes from 'prop-types';
import TicketMoreInfo from '../TicketMoreInfo';
import { List } from 'semantic-ui-react';
import TicketAssignment from '../TicketAssignment';
import TicketStatus from '../TicketStatus';
import TicketResolutionIconsContainer from '../TicketResolutionIconsContainer';
import { TicketContextConsumer } from '../../../context/TicketContext';
import Viewer from '../../common/fields/TUIWrapperViewer';
import CheckPermission from '../../common/CheckPermission';
import * as permissionTypes from '../../../constants/permissionTypes';
const TicketPageHeader = (props) => {
    const {
        ticket,
        ticketActions,
        currentStatusNode,
        currentDeadlineDate,
        currentStatusOverdue,
    } = props;

    const description = ticket?.description || ticket?.activity?.description;

    return (
        <>
            <CheckPermission
                structuralNodeId={ticket.structuralNodeId}
                permission={permissionTypes.VIEW}
            >
            </CheckPermission>

            <div
                className={`ticket-page-header ${
                    description ? 'with-description' : 'without-description'
                }`}
            >
                <div className="column-1">
                    <Viewer initialValue={description} />
                    {ticket?.activity?.moreInfo && (
                        <TicketMoreInfo
                            ticket={ticket}
                            showSidebar={ticketActions.openMoreInfoSidebar}
                        />
                    )}
                </div>

                <div className="column-2">
                    <List horizontal={description ? false : true} floated="right">
                        <List.Item>
                            <List.Content>
                                <List.Header>Assignee:</List.Header>
                                <TicketAssignment />
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Current Status: </List.Header>
                                <TicketStatus
                                    statusNode={currentStatusNode}
                                    color={ticket.statusNodeColor}
                                    deadlineDate={currentDeadlineDate}
                                    currentStatusOverdue={currentStatusOverdue}
                                />
                            </List.Content>
                        </List.Item>
                        {ticket.statusResolutions.length > 0 && (
                            <List.Item>
                                <List.Content>
                                    <List.Header>Resolutions:</List.Header>
                                    <TicketResolutionIconsContainer
                                        size="large"
                                        statusResolutions={ticket.statusResolutions}
                                    />
                                </List.Content>
                            </List.Item>
                        )}
                    </List>
                </div>
            </div>
        </>
    );
};

TicketPageHeader.propTypes = {
    ticket: PropTypes.object,
    ticketActions: PropTypes.object,
    currentStatusNode: PropTypes.object,
    currentDeadlineDate: PropTypes.string,
    currentStatusOverdue: PropTypes.bool,
};

export default TicketContextConsumer(TicketPageHeader);
