import { Button, Modal, ModalActions } from 'semantic-ui-react';
import { AddCombinedProduct } from '@vidende/components/AddCombinedProduct';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { Product } from '../../types';
import { useState } from 'react';
import ExecutionApi from '../../api/executionApi';

const AddCombinedProductModal = ({
    title,
    validProducts,
    selectedDistributorId,
    onSucess,
}) => {
    const [isLoadingAddDistributor, setIsLoadingAddDistributor] =
        useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const handleAddCombinedProduct = async (
        products: Product[],
        productLabel: string,
    ) => {
        setIsLoadingAddDistributor(true);
        // @ts-ignore
        const result = await ExecutionApi.addDistributors({
            distributors: [{ partyId: selectedDistributorId }],
            executionId: products[0].executionId,
            products: products,
            combinedProductLabel: productLabel,
        });
        onSucess();
        setIsLoadingAddDistributor(false);
        if ('error' in result) {
            toastr.error(
                'data' in result.error
                    ? result.error.data.error.message
                    : 'An error occurred',
            );
            return;
        }
        handleClose();
    };
    return (
        <Modal
            open={true}
            onClose={handleClose}
            closeOnDimmerClick={false}
            title
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {validProducts && (
                    <AddCombinedProduct
                        products={validProducts}
                        onAdd={handleAddCombinedProduct}
                        isLoading={isLoadingAddDistributor}
                    />
                )}
            </Modal.Content>
            <ModalActions>
                <Button onClick={handleClose}>Close</Button>
            </ModalActions>
        </Modal>
    );
};

export default AddCombinedProductModal;
