import React, { ReactNode } from 'react';
import { useGetFeatures } from '../../utils/Features';

/**
 * Props for the FeatureFlag component
 */
interface FeatureFlagProps {
    /** The content to render when the feature flag is enabled */
    children: ReactNode;
    /** The feature flag key to check */
    featureFlag: string;
    /** Whether to show or hide the content when the feature flag is enabled */
    showHide: boolean;
    /** Override the feature flag check and always render the children */
    override?: boolean;
}

/**
 * Component that conditionally renders its children based on a feature flag
 *
 * @param featureFlag - The feature flag key to check
 * @param showHide - If true, shows children when flag is enabled; if false, hides children when flag is enabled
 * @param children - The content to conditionally render
 * @param override - If true, bypasses the feature flag check and always renders children
 */
const FeatureFlag = ({
    featureFlag,
    showHide,
    children,
    override,
}: FeatureFlagProps) => {
    const features = useGetFeatures();

    // If override is true, always render children
    if (override) return <>{children}</>;

    // If no feature flag is provided, render nothing
    if (!featureFlag) return null;

    // Check if the feature flag exists in the features object
    const featureExists = Object.prototype.hasOwnProperty.call(
        features,
        featureFlag,
    );

    // Get the feature flag value, defaulting to false if it doesn't exist
    const featureEnabled = featureExists ? features[featureFlag] : false;

    // Determine whether to render children based on showHide and featureEnabled
    const shouldRender = showHide ? featureEnabled : !featureEnabled;

    return shouldRender ? <>{children}</> : null;
};

export default FeatureFlag;
