export const WORKFLOW_FIELD_SHOULD_BE_NOT_EMPTY = {
    id: 'WORKFLOW_FIELD_SHOULD_BE_NOT_EMPTY',
    label: 'Please complete all the required workflow fields',
    description: '', // Will be populated dynamically
};

export const FIELD_SHOULD_BE_NOT_EMPTY = {
    id: 'FIELD_SHOULD_BE_NOT_EMPTY',
    label: 'Please complete all the required fields',
    description: '', // Will be populated dynamically
};
