import { Button, Modal, ModalActions } from 'semantic-ui-react';
import { AddProducts } from '@vidende/components/AddProducts';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { Product } from '../../types';
import { useState } from 'react';
import ExecutionApi from '../../api/executionApi';

const AddProductsModal = ({
    title,
    validProducts,
    selectedDistributorId,
    onSucess,
}) => {
    const [isLoadingAddDistributor, setIsLoadingAddDistributor] =
        useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const handleAddDistributor = async (product: Product) => {
        if (product.type === 'EXECUTION') {
            setIsLoadingAddDistributor(true);
            // @ts-ignore
            const result = await ExecutionApi.addDistributors({
                distributors: [{ partyId: selectedDistributorId }],
                executionId: product.executionId,
            });
            setIsLoadingAddDistributor(false);
            onSucess();
            handleClose();
            if ('error' in result) {
                setIsLoadingAddDistributor(false);
                toastr.error(
                    'data' in result.error
                        ? result.error.data?.error?.message
                        : 'An error occurred',
                );
                return;
            }
        }

        if (product.type === 'TICKETSET') {
            setIsLoadingAddDistributor(true);
            // @ts-ignore
            const result = await ExecutionApi.addDistributors({
                distributors: [{ partyId: selectedDistributorId }],
                rootTicketSetId: product.ticketSetId,
                executionId: product.executionId,
            });
            setIsLoadingAddDistributor(false);
            onSucess();

            if ('error' in result) {
                setIsLoadingAddDistributor(false);
                toastr.error(
                    'data' in result.error
                        ? result.error.data?.error?.message
                        : 'An error occurred',
                );
                return;
            }
        }
        handleClose();
    };
    return (
        <Modal
            open={true}
            onClose={handleClose}
            closeOnDimmerClick={false}
            title
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {validProducts && (
                    <AddProducts
                        onProductInfo={(product) => {
                            let productIds = [];
                            if (
                                product?.entityId &&
                                typeof product?.entityId === 'string'
                            ) {
                                productIds.push(product.entityId);
                            }
                            handleClose();
                        }}
                        products={validProducts}
                        onAdd={handleAddDistributor}
                        isLoading={isLoadingAddDistributor}
                    />
                )}
            </Modal.Content>
            <ModalActions>
                <Button onClick={handleClose}>Close</Button>
            </ModalActions>
        </Modal>
    );
};

export default AddProductsModal;
