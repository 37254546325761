import { Button, Icon, Popup, Table } from 'semantic-ui-react';

import style from './AddProducts.module.css';
import { FormEvent, useState } from 'react';
import Moment from 'react-moment';
import { defaultDateTimeFormat } from '@brallum/vidende/src/constants/config';
import * as React from 'react';
import { Product } from '@brallum/vidende/src/types';
import { ErrorRenderer } from './ErrorRenderer';
import { ApiError } from '@vidende/store/api/base';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
    onClose?: (event: React.MouseEvent<HTMLElement>) => void;
    onAdd?: (product: Product) => void;
    onProductInfo?: (product: Product) => void;
    products: Product[];
    isLoading?: boolean;
    error?: ApiError | SerializedError;
}

export const AddProducts = ({
    onProductInfo,
    onClose,
    onAdd,
    isLoading,
    products,
    error,
}: Props) => {
    return (
        <div className={style.Root}>
            {products.length > 0 && (
                <div className={style.TableContainer}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Product Name
                                </Table.HeaderCell>
                                <Table.HeaderCell>Reference</Table.HeaderCell>
                                <Table.HeaderCell>Class</Table.HeaderCell>
                                <Table.HeaderCell collapsing></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {products
                                .sort((a, b) =>
                                    a.productName.localeCompare(b.productName),
                                )
                                .map((product, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>
                                                {product.productName}{' '}
                                                {onProductInfo && (
                                                    <Icon
                                                        name="info circle"
                                                        size="large"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            onProductInfo(
                                                                product,
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {product.umr}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {product.class}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {product.added ? (
                                                    <Icon
                                                        color="green"
                                                        name="check"
                                                    />
                                                ) : (
                                                    <Popup
                                                        on="click"
                                                        content={
                                                            <>
                                                                <p>
                                                                    I confirm
                                                                    that my firm
                                                                    <br />
                                                                    distributes
                                                                    this
                                                                    product.
                                                                </p>
                                                                <Button
                                                                    loading={
                                                                        isLoading
                                                                    }
                                                                    disabled={
                                                                        isLoading
                                                                    }
                                                                    icon
                                                                    onClick={() =>
                                                                        onAdd(
                                                                            product,
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name="check"
                                                                        color="green"
                                                                    />
                                                                </Button>
                                                            </>
                                                        }
                                                        trigger={
                                                            <Button
                                                                loading={
                                                                    isLoading
                                                                }
                                                                disabled={
                                                                    isLoading
                                                                }
                                                                icon
                                                            >
                                                                <Icon name="add circle" />
                                                            </Button>
                                                        }
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>

                    {error && <ErrorRenderer error={error} />}
                </div>
            )}
        </div>
    );
};
