import { FC, useEffect, useState } from 'react';
import { Button, Dimmer, Form, Loader, Modal, Select } from 'semantic-ui-react';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import ServerConfigurationApi, {
    RatingType,
    RatingValue,
} from '../../api/serverConfigurationApi';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';
import toastr from 'toastr';

interface SaveRatingValueModalProps {
    item: RatingValue;
    onSuccess: () => void;
}

const SaveRatingValueModal: FC<SaveRatingValueModalProps> = ({
    item,
    onSuccess,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState<RatingValue>({
        id: item?.id || null,
        color: item?.color || '',
        deleted: item?.deleted || false,
        ratingTypeId: item?.ratingTypeId || null,
        code: item?.code || '',
        description: item?.description || '',
        label: item?.label || null,
        value: item?.value || 0,
    });
    const dispatch = useDispatch();

    const fetchRatingTypes = async () => {
        setIsSaving(true);
        try {
            const ratingData = await ServerConfigurationApi.getRatingTypes();
            setRatingTypes(ratingData);
            setIsSaving(false);
        } catch (error) {
            toastr.error(error);
            setIsSaving(false);
        }
    };

    const [ratingTypes, setRatingTypes] = useState<RatingType[]>([]);

    useEffect(() => {
        fetchRatingTypes();
    }, []);

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const handleSaveEntity = async () => {
        try {
            setIsSaving(true);
            await ServerConfigurationApi.updateRatingValue(
                item?.id ? { ...formData, id: item.id } : formData,
            );
            toastr.success('Look up type saved');
            onSuccess();
            handleClose();
            setIsSaving(false);
        } catch (error) {
            toastr.error(error);
            setIsSaving(false);
        }
    };

    const handleInputChanged = (event, { name, value, checked }) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            size="small"
            closeOnDimmerClick={false}
        >
            <Dimmer active={isSaving} inverted>
                <Loader disabled={!isSaving} />
            </Dimmer>
            <Modal.Header>
                {item?.id ? `Update ${item.label}` : 'Create new look up value'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        control={Text}
                        label="Label"
                        value={formData.label}
                        onChange={handleInputChanged}
                        name="label"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Code"
                        value={formData.code}
                        onChange={handleInputChanged}
                        name="code"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        type="number"
                        label="Value"
                        value={formData.value}
                        onChange={handleInputChanged}
                        name="value"
                    ></Form.Field>
                    <Form.Field
                        control={TextArea}
                        label="Description"
                        value={formData.description}
                        onChange={handleInputChanged}
                        name="description"
                    ></Form.Field>
                    <Form.Field
                        control={Text}
                        label="Color"
                        value={formData.color}
                        onChange={handleInputChanged}
                        name="color"
                    ></Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={handleSaveEntity}>
                    {item?.id ? 'Update' : 'Save'}
                </Button>
                <Button secondary onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SaveRatingValueModal;
