import * as _ from 'lodash';
import { ticketsPerExecutionPageCall } from '../../constants/config';
import ticketApi from '../../api/ticketApi';
import * as types from '../../constants/actionTypes';
import generateFilter from './generateFilter';

function formTicketGroups(tickets) {
    return _.chunk(tickets, ticketsPerExecutionPageCall);
}

export function performChainOfRequests(dispatch, tickets, isPreview) {
    return new Promise((resolve) => {
        let index = 0;
        const ticketGroups = formTicketGroups(tickets);
        function request() {
            const filter = generateFilter(ticketGroups[index]);
            if (index < ticketGroups.length) {
                return ticketApi.getTickets(filter).then((tickets) => {
                    index++;
                    dispatch({
                        type: types.LOAD_TICKETS_SUCCESS,
                        tickets,
                        isPreview,
                    });
                    return request();
                });
            } else {
                resolve();
            }
            return;
        }
        request();
    });
}

export default performChainOfRequests;
