import {
    Button,
    Form,
    Icon,
    Label,
    Segment,
    Table,
    Header,
    Divider,
} from 'semantic-ui-react';

import style from './AddCombinedProduct.module.css';
import { FormEvent, useState } from 'react';
import Moment from 'react-moment';
import { defaultDateTimeFormat } from '@brallum/vidende/src/constants/config';
import * as React from 'react';
import { Product } from '@brallum/vidende/src/types';
import { sortAndDeduplicateDiagnostics } from 'typescript';
import Text from '@brallum/vidende/src/components/common/fields/Text';

interface Props {
    onClose?: (event: React.MouseEvent<HTMLElement>) => void;
    onAdd?: (products: Product[], productLabel: string) => void;
    products: Product[];
    isLoading?: boolean;
}

export const AddCombinedProduct = ({
    onClose,
    onAdd,
    isLoading,
    products,
}: Props) => {
    const [chosenProducts, setChosenProducts] = useState<Product[]>([]);
    const [productLabel, setProductLabel] = useState('');

    const handleAddProduct = (product: Product) => {
        if (!!chosenProducts.find((p) => p.executionId === product.executionId))
            return;
        setChosenProducts([...chosenProducts, product]);
    };

    const handleDeleteProduct = (product: Product) => {
        setChosenProducts(
            chosenProducts.filter((p) => p.executionId !== product.executionId),
        );
    };

    const handleCreateProduct = () => {
        if (!productLabel) return;
        onAdd(chosenProducts, productLabel);
    };

    return (
        <div className={style.Root}>
            <Header>
                Select the products that are contained within the combined
                product
            </Header>

            {products.length > 0 && (
                <div className={style.TableContainer}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Product Name
                                </Table.HeaderCell>
                                <Table.HeaderCell>Reference</Table.HeaderCell>
                                <Table.HeaderCell>Class</Table.HeaderCell>
                                <Table.HeaderCell collapsing></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {products.map((product, index) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            {product.productName}
                                        </Table.Cell>
                                        <Table.Cell>{product.umr}</Table.Cell>
                                        <Table.Cell>{product.class}</Table.Cell>
                                        <Table.Cell collapsing>
                                            {!!chosenProducts.find(
                                                (p) =>
                                                    p.executionId ===
                                                    product.executionId,
                                            ) ? (
                                                <Icon
                                                    color="green"
                                                    name="check"
                                                />
                                            ) : (
                                                <Button
                                                    loading={isLoading}
                                                    icon
                                                    onClick={() =>
                                                        handleAddProduct(
                                                            product,
                                                        )
                                                    }
                                                >
                                                    <Icon name="add circle" />
                                                </Button>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>
            )}

            {chosenProducts.length > 0 && (
                <Segment>
                    {chosenProducts.map((chosenProduct) => (
                        <Label image size="large">
                            {chosenProduct.productName}
                            <Icon
                                name="delete"
                                onClick={() =>
                                    handleDeleteProduct(chosenProduct)
                                }
                            />
                        </Label>
                    ))}

                    {chosenProducts.length > 1 && (
                        <>
                            <Divider></Divider>
                            <Form>
                                <Text
                                    label="Combined Product Name"
                                    value={productLabel}
                                    onChange={(_, { value }) =>
                                        setProductLabel(value)
                                    }
                                    name="productLabel"
                                    debounce={false}
                                />

                                <Button
                                    primary
                                    onClick={handleCreateProduct}
                                    loading={isLoading}
                                >
                                    Add this combined product
                                </Button>
                            </Form>
                        </>
                    )}
                </Segment>
            )}
        </div>
    );
};
