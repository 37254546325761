import * as _ from 'lodash';

export function getOrderedTicketsFromTicketSets(ticketSets) {
    return _.flatten(
        _.map(ticketSets, (ticketSet) => {
            return _.chain(ticketSet.tickets)
                .sortBy('sort')
                .map((ticket) => ticket.id)
                .value();
        }),
    );
}

export default getOrderedTicketsFromTicketSets;
