import * as permissionTypes from '../../constants/permissionTypes';
import * as assessmentKeys from '../../constants/assessmentKeys';

export function checkUserInputPermission(currentStatusNode, permissions) {
    if (!currentStatusNode || !permissions) return false;
    let requiredPermission = permissionTypes.CREATE;

    switch (currentStatusNode.status.key) {
        case assessmentKeys.ASSESSMENT: {
            requiredPermission = permissionTypes.ASSESS;
            break;
        }
        case assessmentKeys.REVIEW: {
            requiredPermission = permissionTypes.REVIEW;
            break;
        }
    }

    if (permissions.includes(requiredPermission)) {
        return true;
    } else {
        return false;
    }
}

export default checkUserInputPermission;
