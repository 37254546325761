import React, { useEffect, useState, MutableRefObject, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import * as _ from 'lodash';

// import "codemirror/lib/codemirror.css";
// import "tui-editor/dist/tui-editor.min.css";
// import "tui-editor/dist/tui-editor-contents.min.css";

import '@toast-ui/editor/dist/toastui-editor.css';

import useDebounce from '../../../utils/hooks/useDebounce';

import FieldToolTip from './FieldToolTip';
import ShowHideButton from '../ShowHideButton';

import { TuiWithForwardedRefProps } from './TuiWithForwardedRef';
import { EditorProps, Editor as EditorType } from '@toast-ui/react-editor';

import Editor from './TuiEditor';

// let Editor;
// if (typeof window === "undefined" || (typeof window !== "undefined" && window.next) ) {
//   const dynamic = require('next/dynamic');
//   Editor = dynamic(() => import("./TuiEditor"), { ssr: false });
// } else {
//   console.log("window is defined")
//   Editor = require("./TuiEditor").default
// }

import Viewer from './TUIWrapperViewer';

import './RichTextArea.scss';
import Outlier from './Outlier';

const RichTextArea = (props) => {
    const {
        name,
        onChange,
        label,
        editable = true,
        disabled = false,
        required,
        fullWidth = false,
        allowShowHide,
        parentId: propsParentId,
        value: propsValue,
        edited,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;

    const [value, setValue] = useState(propsValue);
    const [visible, setVisible] = useState(allowShowHide ? false : true);
    const editor = useRef<EditorType>(null);
    const [parentId, setParentId] = useState(propsParentId);

    const debouncedValue = useDebounce(value, 300);

    const cleanTextOutput = (v) => {
        return v
            .replace(/(^|[^\n])\n(?!\n)/g, '$1  \n')
            .replace(/( {2}\n)$/g, '\n')
            .replace(/[ ]{2,}/gm, '  ');
    };

    const updateExternalValue = (value) => {
        onChange &&
            onChange(
                {},
                {
                    name,
                    value,
                },
            );
    };

    useEffect(() => {
        if (editor.current) {
            const parentIsChanged = propsParentId !== parentId;
            if (parentIsChanged) {
                setValue(propsValue);
                setParentId(propsParentId);
                editor.current?.getInstance().setMarkdown(propsValue);
                return;
            }
        }
    }, [propsValue, edited]);

    useEffect(() => {
        const hasText = !!value;
        const stateValue = propsValue ? propsValue : '';
        if (hasText || stateValue) {
            const cleanedValue = cleanTextOutput(debouncedValue);
            const cleanedStateValue = cleanTextOutput(stateValue);
            const shouldUpdateExternalValue = !_.isEqual(
                cleanedValue,
                cleanedStateValue,
            );
            if (shouldUpdateExternalValue) {
                updateExternalValue(cleanedValue);
            }
        }
    }, [debouncedValue]);

    const handleOnChange = () => {
        const newValue = editor.current?.getInstance().getMarkdown();
        setValue(newValue);
    };

    // const setValueFromMarkdown = () => {
    //   setValue(editor.current?.getInstance().getMarkdown());
    // };

    return (
        <React.Fragment>
            {editable ? (
                <Form.Field
                    className={`rich-text-area-wrapper field-text-area ${
                        disabled ? 'disabled-mode' : ''
                    } `}
                    width={fullWidth ? 16 : null}
                    required={required}
                    error={error}
                >
                    <label>
                        {label}
                        {!props?.descriptionOnScreen && (
                            <FieldToolTip {...props} />
                        )}
                        {allowShowHide && (
                            <ShowHideButton
                                visible={visible}
                                setVisible={(val) => {
                                    setVisible(val);
                                }}
                            />
                        )}
                        {isManufacturer && dataField && (
                            <Outlier
                                value={value}
                                name={name}
                                outlier={outlier}
                                excludeFromStatistics={excludeFromStatistics}
                                onChange={onChange}
                            />
                        )}
                    </label>
                    {props?.descriptionOnScreen && props?.description && (
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {props.description}
                        </div>
                    )}
                    {visible && !disabled && (
                        <Editor
                            initialValue={propsValue || ' '}
                            previewStyle="vertical"
                            height={'300px'}
                            initialEditType="wysiwyg"
                            onChange={handleOnChange}
                            ref={editor}
                            useCommandShortcut={false}
                            toolbarItems={[
                                ['heading', 'bold', 'italic', 'strike'],
                                ['hr', 'quote'],
                                ['ul', 'ol', 'indent', 'outdent'],
                                ['table', 'link'],
                            ]}
                            hideModeSwitch
                            usageStatistics={false}
                            autofocus={false}
                        />
                    )}
                    {disabled && visible && (
                        <Viewer initialValue={propsValue} />
                    )}
                </Form.Field>
            ) : (
                <Viewer initialValue={propsValue} />
            )}
        </React.Fragment>
    );
};

RichTextArea.propTypes = {
    editable: PropTypes.bool,
    edited: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    allowShowHide: PropTypes.bool,
    parentId: PropTypes.number,
    isManufacturer: PropTypes.bool,
};

export default RichTextArea;
