import Text from '@brallum/vidende/src/components/common/fields/Text';
import { Button, Form, Message } from 'semantic-ui-react';

interface ChangePasswordFormProps {
    loading: boolean;
    validationErrors: any;
    changePasswordError: any;
    formData: {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
    onChange: (
        event: null,
        data: {
            value: string;
            name: string;
            outlier?: boolean;
            excludeFromStatistics?: boolean;
        },
    ) => void;
    onChangePassword: () => void;
}

const ChangePasswordForm = ({
    loading,
    validationErrors,
    changePasswordError,
    formData,
    onChange,
    onChangePassword,
}: ChangePasswordFormProps) => {
    return (
        <Form className="form-login" onSubmit={onChangePassword}>
            <Text
                label="Old Password"
                value={formData.oldPassword}
                onChange={onChange}
                name="oldPassword"
                type="password"
                errors={validationErrors}
                debounce={false}
            />
            <Text
                label="New Password"
                value={formData.newPassword}
                onChange={onChange}
                name="newPassword"
                type="password"
                debounce={false}
                errors={validationErrors}
            />
            <Text
                label="Confirm Password"
                value={formData.confirmPassword}
                onChange={onChange}
                name="confirmPassword"
                type="password"
                errors={validationErrors}
                debounce={false}
            />
            <Button disabled={loading} loading={loading} type="submit">
                Submit
            </Button>
            {changePasswordError && (
                <Message error content={changePasswordError} />
            )}
        </Form>
    );
};
export default ChangePasswordForm;
