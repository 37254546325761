import { useState } from 'react';
import { Container, Divider, Grid, Segment } from 'semantic-ui-react';
import ChangePasswordForm from '@vidende/components/ChangePasswordForm';
import { checkValidation } from '../../utils/Validation';
import ValidationSchema from '../../utils/ValidationSchema';
import UserApi from '../../api/userApi';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import { useAppSelector } from '../../actions/store';

const ChangePasswordPage = () => {
    const history = useHistory();
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const [changePasswordError, setChangePasswordError] = useState<any>();
    const [validationErrors, setValidationErrors] = useState<any>();

    const currentUser = useAppSelector((state) => state.currentUser);

    const handleChangePassword = async () => {
        setIsLoading(true);
        const validation = checkValidation(
            formData,
            ValidationSchema.changePasswordData,
        );

        if (!validation.valid) {
            setValidationErrors(validation.errors);
            setIsLoading(false);
            return;
        }

        try {
            await UserApi.updatePassword({
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
                email: currentUser.details.email,
            });
            setIsLoading(false)
            toastr.success('Password was successfuly changed');
            history.push('/');
        } catch (error) {
            setIsLoading(false)
            setChangePasswordError(
                error.message || error.response?.data?.error?.message,
            );
        }
    };

    return (
        <Container className="login-page">
            <Grid stackable centered columns={3}>
                <Grid.Column>
                    <Divider hidden />
                    <Segment>
                        <ChangePasswordForm
                            formData={formData}
                            changePasswordError={changePasswordError}
                            loading={isLoading}
                            onChange={(_, { name, value }) =>
                                setFormData((prevState) => ({
                                    ...prevState,
                                    [name]: value,
                                }))
                            }
                            onChangePassword={handleChangePassword}
                            validationErrors={validationErrors}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default ChangePasswordPage;
