import { FC, Fragment } from 'react';
import FilterAndPaginate from '../common/FilterAndPaginate';
import { Button, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import SortableHeaderCell from '../common/SortableHeaderCell';
import Pagination from '../common/Pagination';
import { useDispatch } from 'react-redux';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import ConfirmWrapper from '../common/ConfirmWrapper';
import ServerConfigurationApi, {
    RatingValue,
} from '../../api/serverConfigurationApi';
import toastr from 'toastr';

const RatingValuesTable: FC<any> = ({
    paginatedItems,
    handleSortChange,
    handleAddFilter,
    handlePaginationChange,
    paginationOptions,
    uiActions,
    confirmPopupActions,
    fetchRatingValuesById,
    ...rest
}) => {
    const dispatch = useDispatch();
    const { showConfirmDialog } = confirmPopupActions;
    const handleEdit = (item) => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_RATING_VALUE_MODAL,
                modalProps: {
                    onSuccess: () => fetchRatingValuesById(item.ratingTypeId),
                    item,
                },
            }),
        );
    };

    const removeClicked = (item) => {
        showConfirmDialog(
            `Are you sure you want to remove look up value "${item.ratingValueName}"?`,
            () => handleRemove(item),
        );
    };

    const handleRemove = async (item) => {
        try {
            await ServerConfigurationApi.updateRatingValue({
                ...item,
                deleted: true,
            });
            toastr.success('Look up value deleted');
            fetchRatingValuesById(item.ratingTypeId);
        } catch (error) {
            toastr.error(error);
        }
    };
    const unRemoveClicked = async (item) => {
        try {
            await ServerConfigurationApi.updateRatingValue({
                ...item,
                deleted: false,
            });
            toastr.success('Look up value restored');
            fetchRatingValuesById(item.ratingTypeId);
        } catch (error) {
            toastr.error(error);
        }
    };
    return (
        <>
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="code"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Code
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="label"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Label
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="value"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Value
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="description"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Description
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="color"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Color
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="deleted"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Deleted
                        </SortableHeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {paginatedItems.map((item: RatingValue) => (
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.code}</Table.Cell>
                            <Table.Cell>{item.label}</Table.Cell>
                            <Table.Cell>{item.value}</Table.Cell>
                            <Table.Cell>{item.description}</Table.Cell>
                            <Table.Cell>{item.color}</Table.Cell>
                            <Table.Cell>
                                {item.deleted ? (
                                    <Icon color="red" name="x" />
                                ) : (
                                    ''
                                )}
                            </Table.Cell>
                            <Table.Cell collapsing textAlign="center">
                                <Popup
                                    wide
                                    trigger={
                                        <Button
                                            icon="edit"
                                            onClick={() => handleEdit(item)}
                                        />
                                    }
                                    content={'Edit'}
                                    position="top center"
                                />
                                {!item.deleted ? (
                                    <Popup
                                        wide
                                        trigger={
                                            <Button
                                                icon={'trash'}
                                                onClick={() =>
                                                    removeClicked(item)
                                                }
                                            />
                                        }
                                        content={'Delete'}
                                        position="top center"
                                    />
                                ) : (
                                    <Popup
                                        wide
                                        trigger={
                                            <Button
                                                icon={'reply'}
                                                onClick={() =>
                                                    unRemoveClicked(item)
                                                }
                                            />
                                        }
                                        content={'Restore'}
                                        position="top center"
                                    />
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination
                activePage={paginationOptions.activePage}
                onPageChange={handlePaginationChange}
                totalPages={paginationOptions.totalPages}
            />
        </>
    );
};

function mapOptions(props) {
    return {
        items: props.ratingValues || [],
    };
}

export default FilterAndPaginate(mapOptions)(ConfirmWrapper(RatingValuesTable));
