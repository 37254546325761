import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Input, Icon, Popup } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';

const Percentage = (props) => {
    const {
        label,
        value,
        name,
        onChange,
        disabled = false,
        required,
        editable = true,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
        description,
        descriptionOnScreen,
    } = props;

    if (!editable) {
        return <React.Fragment>{value}%</React.Fragment>;
    }

    return (
        <Form.Field required={required} error={error}>
            <label>
                {label}
                {!descriptionOnScreen && <FieldToolTip {...props} />}
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            {descriptionOnScreen && description && (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {props.description}
                </div>
            )}
            <Input
                type={'number'}
                placeholder={label}
                value={value || ''}
                onChange={onChange}
                icon="percent"
                disabled={disabled}
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
            />
        </Form.Field>
    );
};

Percentage.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
    description: PropTypes.string,
};

Percentage.defaultProps = {
    value: '',
};

export default Percentage;
