import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import * as _ from 'lodash';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

import useDebounce from '../../../utils/hooks/useDebounce';
import './TextArea.scss';
import Outlier from './Outlier';

const TextArea = (props) => {
    const {
        label,
        value: propsValue,
        onChange,
        editable = true,
        name,
        disabled = false,
        required,
        errors,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
        readonly,
        placeholder,
    } = props;
    const [value, setValue] = useState(propsValue);
    const [isInitial, setIsInitial] = useState(true);
    const debouncedValue = useDebounce(value, 300);
    const fieldErrors = errors ? errors[name] : undefined;

    useEffect(() => {
        if (!isInitial) {
            onChange && onChange(null, { value: debouncedValue, name });
        } else {
            setIsInitial(false);
        }
    }, [debouncedValue]);

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    const handleValueChanged = (e, { value }) => {
        setValue(value);
    };

    return (
        <React.Fragment>
            {editable ? (
                <Form.Field
                    className="field-text-area"
                    required={required}
                    error={error}
                >
                    <label>
                        {label}
                        {!props?.descriptionOnScreen && (
                            <FieldToolTip {...props} />
                        )}
                        {isManufacturer && dataField && (
                            <Outlier
                                value={value}
                                name={name}
                                outlier={outlier}
                                excludeFromStatistics={excludeFromStatistics}
                                onChange={onChange}
                            />
                        )}
                    </label>
                    {props?.descriptionOnScreen && props?.description && (
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {props.description}
                        </div>
                    )}
                    <Form.TextArea
                        name={name}
                        placeholder={
                            placeholder === undefined ? label : placeholder
                        }
                        value={value || ''}
                        onChange={handleValueChanged}
                        disabled={disabled || readonly}
                        className={readonly && 'readonly'}
                    />
                    {_.isArray(fieldErrors) && (
                        <FieldErrors errors={fieldErrors} />
                    )}
                </Form.Field>
            ) : (
                <div>{value}</div>
            )}
        </React.Fragment>
    );
};

TextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    errors: PropTypes.object,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    readonly: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
    placeholder: PropTypes.string,
};

TextArea.defaultProps = {
    value: '',
};

export default TextArea;
