import React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import UserWrapper from '../../user/User';
import * as permissionTypes from '../../../constants/permissionTypes';
import * as nodeTypes from '../../../constants/nodeTypes';
import MainMenuItem from './MainMenuItem';

import './MainMenu.scss';
import { useGetFeatures } from '../../../utils/Features';

const SITE_THEME = window.JSW_CONFIG.siteTheme;

const mainMenuItems = (currentUser) => [
    // { label: 'Your Dashboard', path: '/', icon: 'line graph' },
    {
        label: 'Products',
        path: '/products',
        icon: 'boxes',
        featureFlag: 'VisRisk',
    },
    {
        label: 'Distributors',
        icon: 'factory',
        featureFlag: 'VisRiskFairValue',
        children: [
            {
                label: 'Distributors',
                path: `/party/${currentUser.details.party.id}/distributors`,
                icon: 'factory',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Product Management',
                path: '/distributor/products',
                icon: 'boxes',
                featureFlag: 'VisRiskFairValue',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ADMIN,
            },
        ],
    },
    {
        label: 'MI',
        icon: 'factory',
        children: [
            {
                label: 'Product Status',
                path: '/fairvalueProductStatus',
                icon: 'boxes',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'POG Overview',
                path: '/fairvaluePOGOverview',
                icon: 'pie chart',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Distributor Status',
                path: '/fairvalueDistributorStatus',
                icon: 'factory',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Fair Value Summary',
                path: '/fairvalueSummary',
                icon: 'boxes',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Customer Cost Analysis (TM)',
                path: '/dashboards/4AA1E670-C4D0-11EE-A480-9D5C16990076',
                icon: 'money',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Customer Cost Analysis (Distributor)',
                path: '/dashboards/A61E39A0-E0C2-11EE-8FD8-9FC1681E0B71',
                icon: 'money',
                featureFlag: 'VisRiskFairValue',
            },
            {
                label: 'Product Owner Status',
                path: '/dashboards/73969E90-F224-11EF-946D-D5BDA2578D2F',
                icon: 'money',
                featureFlag: 'VisRiskConsumerDuty',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ASSESS,        
            },
            {
                label: 'Product Reviewer Status',
                path: '/dashboards/651CB7A0-F224-11EF-946D-D5BDA2578D2F',
                icon: 'money',
                featureFlag: 'VisRiskConsumerDuty',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.REVIEW,        
        
            },
            { label: 'Programme Status', path: '/programmeStatus', icon: 'rocket' },            
        ],
    },
    {
        label: 'Review Management',
        path: '/reviewManagement',
        icon: 'clipboard check',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
        featureFlag: 'Vidende',
    },
    {
        label: 'Risk Distribution',
        path: '/riskDistribution',
        icon: 'exclamation triangle',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
        featureFlag: 'Vidende',
    },
    {
        label: 'Users',
        path: '/users',
        icon: 'user',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
        featureFlag: 'VidendeUsersMenu',
    },
    {
        label: 'Delegates',
        path: '/delegates',
        icon: 'users',
        featureFlag: 'VisRiskDelegates',
    },
    {
        label: 'Events',
        path: '/events',
        icon: 'bell',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
        featureFlag: 'VidendeEventsMenu',
    },
    {
        label: 'Thresholds',
        path: `/thresholds`,
        icon: 'balance scale',
        permission: nodeTypes.Party,
        permissionType: permissionTypes.SUPER,        
    },
    {
        label: 'Question Bank',
        path: '/questions',
        icon: 'question circle',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
    },
    {
        label: 'Workflow Management',
        path: '/workflows',
        icon: 'exchange',
        structuralNodeId: 2,
        permissionType: permissionTypes.SUPER,
    },
    {
        label: 'Administration',
        children: [
            {
                label: 'Party Management',
                path: '/parties',
                icon: 'building',
            },
            {
                label: 'Users',
                path: '/users',
                icon: 'users',
                featureFlag: 'VisRiskUsersMenu',
            },
            { label: 'Programme Search', path: '/executions', icon: 'list' },
            { label: 'Ticket Search', path: '/ticketsearch', icon: 'briefcase' },
            {
                label: 'Queue Status',
                path: '/queueStatus',
                icon: 'exclamation triangle',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ADMIN,
                featureFlag: 'QueueStatusMenu',
            },
            {
                label: 'Admin Panel',
                path: '/admin',
                icon: 'cogs',
                structuralNodeId: 2,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'API Keys',
                path: '/ApiKeys',
                icon: 'cogs',
                structuralNodeId: 2,
                permissionType: permissionTypes.ADMIN,
            },
            {
                label: 'Server Configuration',
                path: '/serverConfiguration',
                icon: 'setting',
                structuralNodeId: 2,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'Look Up Configuration',
                path: '/lookUpConfiguration',
                icon: 'setting',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ADMIN,
            },
            {
                label: 'Rating Configuration',
                path: '/ratingConfiguration',
                icon: 'setting',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ADMIN,
            },
            {
                label: 'Permissions',
                path: '/permission/1',
                icon: 'tree',
                structuralNodeId: 2,
                permissionType: permissionTypes.ADMIN,
            },
            {
                label: 'Events',
                path: '/events',
                icon: 'bell',
                featureFlag: 'VisRiskEventsMenu',
                permission: nodeTypes.Party,
                permissionType: permissionTypes.ADMIN,
            },
        ]
    },
    {
        label: 'Reporting',
        children: [
            {
                label: 'Charts',
                path: '/charts',
                icon: 'pie chart',
                structuralNodeId: 2,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'API Calls',
                path: '/apicalls',
                icon: 'server',
                structuralNodeId: 2,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'Dashboards',
                path: '/dashboards',
                icon: 'dashboard',
                structuralNodeId: 2,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'Metrics',
                path: '/metrics',
                icon: 'line graph ',
                permission: nodeTypes.ProgrammeGroup,
                permissionType: permissionTypes.SUPER,
            },
            {
                label: 'Metric Reports',
                path: '/metricReports',
                icon: 'tasks  ',
                permission: nodeTypes.ProgrammeGroup,
                permissionType: permissionTypes.SUPER,
            },
        ],
        permission: nodeTypes.Party,
        permissionType: permissionTypes.SUPER,
    },
    {
        label: 'Programme Planning',
        path: '/executionPlans',
        icon: 'pencil',
        permission: nodeTypes.Party,
        permissionType: permissionTypes.ADMIN,
    },
    {
        label: 'Programme Library',
        path: '/programmes',
        icon: 'book',
        permission: nodeTypes.Party,
        permissionType: permissionTypes.ADMIN,
    },
];

const MainMenu = ({ uiActions, isMenuExpanded, currentUser }) => {
    const featureFlags = useGetFeatures();

    return (
        <Menu
            vertical
            icon="labeled"
            borderless
            fluid
            width="thin"
            className="main-menu"
            size="tiny"
        >
            <Menu.Item className="main-menu__logo" as={NavLink} to="/">
                &nbsp;
            </Menu.Item>
            {mainMenuItems(currentUser).map((item, index) => {
                // There is a feature flag set on the menu item, it is false or does not exist in the array, then return null
                // if (item.featureFlag && (!featureFlags[item.featureFlag] || !(item.featureFlag in featureFlags))) {
                if (item.featureFlag && !featureFlags[item.featureFlag]) {
                    return null;
                }
                return (
                    <MainMenuItem
                        options={item}
                        key={index}
                        isMenuExpanded={isMenuExpanded}
                    />
                );
            })}

            {currentUser.details.party.dashboards &&
                currentUser.details.party.dashboards.length > 0 && (
                    <React.Fragment>
                        {currentUser.details.party.dashboards.map(
                            (dashboard, index) =>
                                isMenuExpanded ? (
                                    <Menu.Item
                                        key={`dash-${index}`}
                                        as={NavLink}
                                        to={`/dashboards/${dashboard.id}`}
                                    >
                                        <Icon name="dashboard" />
                                        <span>{dashboard.label}</span>
                                    </Menu.Item>
                                ) : (
                                    <Popup
                                        key={`dash-${index}`}
                                        trigger={
                                            <Menu.Item
                                                as={NavLink}
                                                to={`/dashboards/${dashboard.id}`}
                                            >
                                                <Icon name="dashboard" />
                                            </Menu.Item>
                                        }
                                        disabled
                                        position="right center"
                                        content={dashboard.label}
                                        basic
                                    />
                                ),
                        )}
                    </React.Fragment>
                )}
            <Menu.Item
                className="menu-arrow"
                onClick={() => uiActions.changeMenuState(!isMenuExpanded)}
            >
                <Icon
                    name={`angle double ${isMenuExpanded ? 'left' : 'right'}`}
                />
            </Menu.Item>
        </Menu>
    );
};

MainMenu.propTypes = {
    uiActions: PropTypes.object,
    currentUser: PropTypes.object,
    isMenuExpanded: PropTypes.bool,
};

export default withRouter(UserWrapper(MainMenu));
