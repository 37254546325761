import * as _ from 'lodash';
import jsonLogic from 'json-logic-js';
import * as transitionErrors from '../../constants/transitionErrors';
import { ExecutionTicketExtended, StatusNode, Transition } from '../../types';

interface ValidationError {
    label: string;
    description?: string;
    id?: string;
}

interface ValidationResult {
    errors: ValidationError[] | false;
}

function getCurrentResolution(
    ticket: ExecutionTicketExtended,
    currentStatusNode: StatusNode,
) {
    const { resolutions } = currentStatusNode.status;
    const currentResolution =
        resolutions.length > 0
            ? ticket.statusResolutions?.find(
                  (resolution) =>
                      resolution.statusId === resolutions[0].statusId,
              )
            : null;
    return currentResolution;
}

const validation = {
    TICKET_ASSIGNED: (ticket: ExecutionTicketExtended) => {
        return !!ticket.assignedPrincipalId;
    },
    RESOLUTION_CHOSEN: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return !!currentResolution;
    },
    FORM_COMPLETE: (ticket: ExecutionTicketExtended) => {
        if (ticket.edited || !ticket.activity) return false;
        const fields = ticket.activity.fields
            .filter((field) => field.required === true) // Only check required fields
            .filter((field) => {
                const entry = ticket.entries?.find(
                    (entry) => entry.field?.id === field.id,
                );
                if (!entry || entry.value === '') return true;
                return false;
            });
        return fields.length === 0;
    },
    RESOLUTION_CHOSEN_POSITIVE: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            _.get(currentResolution, 'resolution.resolutionType.id') ===
                'POSITIVE'
        );
    },
    RESOLUTION_CHOSEN_NEGATIVE: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            _.get(currentResolution, 'resolution.resolutionType.id') ===
                'NEGATIVE'
        );
    },
    RESOLUTION_CHOSEN_NEUTRAL: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            currentResolution.resolution.resolutionType.label === 'NEUTRAL'
        );
    },
    RESOLUTION_CHOSEN_NOT_APPLICABLE: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            currentResolution.resolution.resolutionType.label ===
                'NOT_APPLICABLE'
        );
    },
    WORKFLOW_FORM_COMPLETE: (ticket: ExecutionTicketExtended) => {
        if (ticket.edited) return false;
        const fields = ticket.currentStatusNode.status.fields
            .filter((field) => field.required === true) // Only check required fields
            .filter((field) => {
                const entry = ticket.workflowEntries?.find(
                    (entry) => entry.workFlowStatusFieldId === field.id,
                );
                if (!entry || entry.value === '') return true;
                return false;
            });
        return fields.length === 0;
    },
    RESOLUTION_CHOSEN_POSITIVE_OR_NOT_APPLICABLE: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            (currentResolution.resolution.resolutionType.label === 'POSITIVE' ||
                currentResolution.resolution.resolutionType.label ===
                    'NOT_APPLICABLE')
        );
    },
    RESOLUTION_CHOSEN_NEGATIVE_OR_NEUTRAL: (
        ticket: ExecutionTicketExtended,
        currentStatusNode: StatusNode,
    ) => {
        const currentResolution = getCurrentResolution(
            ticket,
            currentStatusNode,
        );
        return (
            !!currentResolution &&
            (currentResolution.resolution.resolutionType.label === 'NEGATIVE' ||
                currentResolution.resolution.resolutionType.label === 'NEUTRAL')
        );
    },
};

export function validateTransition(
    ticket: ExecutionTicketExtended,
    transition: Transition,
    currentStatusNode: StatusNode,
): boolean | ValidationResult {
    const { activity } = ticket;
    const statusKeyIsAssessment = currentStatusNode.status.key === 'ASSESSMENT';
    const currentStatus = currentStatusNode.status;
    const requiredWorkflowFields = currentStatus.fields.filter(
        (field) => field.required === true,
    );
    const requiredEntryFields = activity
        ? activity.fields.filter((field) => field.required === true)
        : [];

    if (
        (!transition.conditions || transition.conditions.length === 0) &&
        !requiredWorkflowFields.length &&
        !(statusKeyIsAssessment && requiredEntryFields.length)
    )
        return true;

    let result = [] as ValidationError[];

    //console.log('transition', transition);

    if (transition.conditions && transition.conditions.length) {
        const failedConditions = transition.conditions.filter((condition) => {
            const conditionKey = condition.id;
            const validationFn =
                validation[conditionKey as keyof typeof validation];
            if (!validationFn) return false;
            return !validationFn(ticket, currentStatusNode);
        });

        result = failedConditions.map((condition) => ({
            id: condition.id,
            label: condition.label,
        }));
    }

    if (requiredWorkflowFields.length) {
        // Create values map for jsonLogic evaluation
        const values = _.keyBy(
            ticket.workflowEntries || [],
            (entry) => entry.field.fieldKey,
        );

        // Filter fields based on display logic first
        const visibleRequiredWorkflowFields = requiredWorkflowFields.filter(
            (field) => {
                if (field.displayLogic) {
                    return jsonLogic.apply(field.displayLogic, values);
                }
                return true; // If no display logic, field is visible
            },
        );

        const emptyFields = visibleRequiredWorkflowFields.filter(
            (workFlowField) => {
                const entry = ticket.workflowEntries?.find(
                    (entry) => entry.field.id === workFlowField.id,
                );
                return !entry || entry.value === '';
            },
        );

        if (emptyFields.length > 0) {
            const missingFieldLabels = emptyFields
                .map((field) => field.label)
                .join(', ');
            const error = {
                ...transitionErrors.WORKFLOW_FIELD_SHOULD_BE_NOT_EMPTY,
                description: ` (${missingFieldLabels})`,
            };
            result.push(error);
        }
    }

    if (statusKeyIsAssessment && requiredEntryFields.length) {
        // Create values map for jsonLogic evaluation
        const values = _.keyBy(
            ticket.entries || [],
            (entry) => entry.field.fieldKey,
        );

        // Filter fields based on display logic first
        const visibleRequiredFields = requiredEntryFields.filter((field) => {
            if (field.displayLogic) {
                return jsonLogic.apply(field.displayLogic, values);
            }
            return true; // If no display logic, field is visible
        });

        // Now check if visible required fields have values
        const emptyFields = visibleRequiredFields.filter((entryField) => {
            const entry = ticket.entries?.find(
                (entry) => entry.field?.id === entryField.id,
            );
            return !entry || entry.value === '';
        });

        if (emptyFields.length > 0) {
            const missingFieldLabels = emptyFields
                .map((field) => field.label)
                .join(', ');
            const error = {
                ...transitionErrors.FIELD_SHOULD_BE_NOT_EMPTY,
                description: ` (${missingFieldLabels})`,
            };
            result.push(error);
        }
    }

    return result.length > 0 ? { errors: result } : { errors: false };
}

export { getCurrentResolution };
export default validateTransition;
