import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const InstanceLink = (props) => {
    const { id, modelName, value } = props;
    if (typeof process !== 'undefined' && process.env.SERVER_REND)
        return <React.Fragment>{value}</React.Fragment>;
    return <Link to={`/${modelName}/${id}`}>{value}</Link>;
};

InstanceLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modelName: PropTypes.string,
    value: PropTypes.string,
};

export default InstanceLink;
