import { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Header,
    Label,
    Loader,
    Segment,
    Table,
} from 'semantic-ui-react';
import { useAppSelector } from '../../actions/store';
import { useDispatch } from 'react-redux';
import { loadDistributorInfos } from '../../actions/partyActions';
import SearchableSelect from '../common/fields/SearchableSelect';
import {
    Entity,
    ExecutionTicketSet,
    Party,
    PartyProduct,
    Product,
    ProductType,
    StatusLabel,
} from '../../types';
import PartyApi from '../../api/partyApi';
import toastr from 'toastr';
import ExecutionApi from '../../api/executionApi';
import { Link } from 'react-router-dom';
import { generateProductLabelsFromEntities } from '../../utils/productUtils';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { groupBy, map } from 'lodash';
import EntityApi from '../../api/entityApi';

const DistributorProductsPage = () => {
    const dispatch = useDispatch();
    const distributorInfos = useAppSelector(
        (state) => state.distributorInfos?.list,
    );

    const ownerPartyId = useAppSelector(
        (state) => state.constants.options.ownerPartyId,
    );

    const [isLoading, setIsLoading] = useState(true);
    const [isPartyProductsLoading, setIsPartyProductsLoading] = useState(false);

    const [selectedDistributorId, setSelectedDistributorId] =
        useState<string>();
    const [parentParties, setParentParties] = useState<Party[]>([]);
    const [selectedParentPartyId, setSelectedParentPartyId] =
        useState<string>();
    const [partyProducts, setPartyProducts] = useState<PartyProduct[]>();
    const [parentPartyProducts, setParentPartyProducts] =
        useState<ExecutionTicketSet[]>();
    const [availableProducts, setAvailableProducts] = useState<PartyProduct[]>(
        [],
    );

    const parentPartyIsOwner = ownerPartyId === selectedParentPartyId;

    const fetchDistributors = async () => {
        await dispatch(loadDistributorInfos());
        setIsLoading(false);
    };

    const fetchParentParties = async (parentPartiesIds: string[]) => {
        console.log('Fetching parent parties with IDs:', parentPartiesIds);
        setIsPartyProductsLoading(true);
        try {
            const res = await PartyApi.getParties({
                where: { id: { inq: parentPartiesIds } },
            });
            console.log('Parent parties fetched:', res);
            setIsPartyProductsLoading(false);
            setParentParties(res);
            if (res.length === 1) {
                console.log('Auto-selecting parent party:', res[0].id);
                setSelectedParentPartyId(res[0].id);
            }
        } catch (error) {
            console.error('Error fetching parent parties:', error);
            setIsPartyProductsLoading(false);
            toastr.error(error);
        }
    };

    const fetchPartyProducts = async () => {
        setIsPartyProductsLoading(true);
        try {
            const res = await EntityApi.getPartyProducts(selectedDistributorId);
            setIsPartyProductsLoading(false);
            setPartyProducts(res);
        } catch (error) {
            setIsPartyProductsLoading(false);
            toastr.error(error);
        }
    };

    const fetchAvailableProducts = async () => {
        //console.log('Fetching available products for parent:', selectedParentPartyId);
        setIsPartyProductsLoading(true);
        try {
            const res = await EntityApi.getPartyProducts(selectedParentPartyId);
            //console.log('Available products raw response:', res);
            setIsPartyProductsLoading(false);
            setAvailableProducts(res || []);
        } catch (error) {
            //console.error('Error fetching available products:', error);
            setIsPartyProductsLoading(false);
            toastr.error(`Failed to fetch available products: ${error}`);
        }
    };

    const fetchParentPartyProducts = async () => {
        setIsPartyProductsLoading(true);
        try {
            const res = await ExecutionApi.getExecutionTicketSets(
                parentPartyIsOwner ? undefined : selectedParentPartyId,
                parentPartyIsOwner ? 'CD-FV-M' : 'CD-FV-D',
            );
            setIsPartyProductsLoading(false);
            setParentPartyProducts(res);
        } catch (error) {
            setIsPartyProductsLoading(false);
            toastr.error(error);
        }
    };

    const fetchTableData = () => {
        console.log('Fetching table data with:', {
            selectedDistributorId,
            selectedParentPartyId,
            parentPartyIsOwner,
        });
        fetchPartyProducts();
        fetchParentPartyProducts();
        fetchAvailableProducts();
    };

    useEffect(() => {
        fetchDistributors();
    }, []);

    useEffect(() => {
        if (selectedDistributorId) {
            const distributor = distributorInfos.find(
                (item) => item.id === selectedDistributorId,
            );
            console.log('Selected distributor:', distributor);
            fetchParentParties(distributor?.parentPartyIds?.ids);
        }
    }, [selectedDistributorId]);

    useEffect(() => {
        if (selectedParentPartyId) {
            console.log('Parent party ID changed to:', selectedParentPartyId);
            fetchTableData();
        }
    }, [selectedParentPartyId]);

    const groupedValidProducts = groupBy(
        availableProducts?.filter((product) => product.executionTicketSetId),
        'executionTicketSetId',
    );

    const validProductsDist: Product[] =
        map(groupedValidProducts, (products) => {
            const productEntities = products
                .filter((product) => product.entity)
                .map((product) => product.entity as Entity);

            const { label, productClass, UMR } =
                generateProductLabelsFromEntities(
                    productEntities,
                    products[0].executionTicketSetlabel,
                );

            const ids = productEntities
                .map((entity) => entity.id)
                .sort((a, b) => a.localeCompare(b))
                .join();

            const added = !!partyProducts?.find((product) => {
                return (
                    product.partyId === selectedDistributorId &&
                    product.executionOwnerPartyId === selectedParentPartyId &&
                    product.entityId === ids
                );
            });

            const product: Product = {
                executionId: products[0].executionId,
                ticketSetId: products[0].executionTicketSetId,
                productName: label,
                entityId: productEntities?.map((entity) => entity.id),
                umr: UMR,
                class: productClass,
                carrier: 'carrier',
                added,
                type: 'TICKETSET' as ProductType,
            };
            return product;
        }) || [];

    const validProductsOwner: Product[] =
        availableProducts
            ?.filter(
                (product) => !product.executionTicketSetId || !product.entity,
            )
            .map((availableProduct) => {
                if (!availableProduct.entity)
                    throw new Error('No entity found');
                const { label, productClass, UMR } =
                    generateProductLabelsFromEntities(
                        [availableProduct.entity as Entity],
                        availableProduct.executionTicketSetlabel,
                    );

                const added = !!partyProducts?.find(
                    (product) => product.entityId === availableProduct.entityId,
                );

                const product: Product = {
                    executionId: availableProduct.executionId,
                    ticketSetId: undefined,
                    productName: label,
                    entityId: availableProduct.entity?.id,
                    umr: UMR,
                    class: productClass,
                    carrier: 'carrier',
                    added,
                    type: 'EXECUTION' as ProductType,
                };
                return product;
            }) || [];

    const validProducts = [...validProductsDist, ...validProductsOwner];

    const handleOpenAddProductsModal = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_PRODUCTS_MODAL,
                modalProps: {
                    title: parentPartyProducts
                        ? 'Add products'
                        : 'Add standalone products',
                    validProducts,
                    selectedDistributorId,
                    onSucess: () => {
                        fetchParentPartyProducts();
                        fetchPartyProducts();
                    },
                },
            }),
        );
    };
    const handleOpenAddCombinedProductModal = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_COMBINED_PRODUCT_MODAL,
                modalProps: {
                    title: 'Add a combined product',
                    validProducts,
                    selectedDistributorId,
                    onSucess: () => {
                        fetchParentPartyProducts();
                        fetchPartyProducts();
                    },
                },
            }),
        );
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            {!isLoading && (
                <>
                    <Header as="h2">Distributor Products</Header>
                    <Segment>
                        <Form.Input>
                            <SearchableSelect
                                label="Distributor"
                                value={selectedDistributorId}
                                options={distributorInfos}
                                valueField="id"
                                onChange={(_, { value }) => {
                                    setAvailableProducts(null);
                                    setParentPartyProducts(null);
                                    setSelectedParentPartyId(null);
                                    setPartyProducts(null);
                                    setSelectedParentPartyId(null);
                                    setSelectedDistributorId(value);
                                }}
                            />
                        </Form.Input>
                        <Form.Input>
                            <SearchableSelect
                                label="Product Source"
                                value={selectedParentPartyId}
                                options={parentParties}
                                valueField="id"
                                onChange={(_, { value }) => {
                                    setSelectedParentPartyId(value);
                                }}
                            />
                        </Form.Input>
                    </Segment>

                    <Loader active={isPartyProductsLoading && !partyProducts} />

                    <Header>
                        Distributed Products from{' '}
                        {
                            parentParties?.find(
                                (item) => item.id === selectedParentPartyId,
                            )?.label
                        }
                    </Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Product Name
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    Reference
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    Class
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    Status
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {partyProducts
                            ?.sort((a, b) =>
                                a.executionTicketSetlabel.localeCompare(
                                    b.executionTicketSetlabel,
                                ),
                            )
                            .map((product, index) => {
                                if (
                                    product.partyId === selectedDistributorId &&
                                    product.executionOwnerPartyId ===
                                        selectedParentPartyId
                                ) {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>
                                                <Link
                                                    to={product.clientLinkPath}
                                                >
                                                    <a>
                                                        {
                                                            product.executionTicketSetlabel
                                                        }
                                                    </a>
                                                </Link>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {product.productReference}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {product.productClass}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                <Label
                                                    color={
                                                        product?.clientStatusColor
                                                    }
                                                >
                                                    {product?.clientStatusLabel}
                                                </Label>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                }
                            })}
                    </Table>
                    {parentPartyProducts && partyProducts && (
                        <>
                            <Button
                                loading={isPartyProductsLoading}
                                onClick={handleOpenAddProductsModal}
                            >
                                {parentPartyProducts
                                    ? 'Add products'
                                    : 'Add standalone products'}
                            </Button>
                            {parentPartyIsOwner && (
                                <Button
                                    loading={isPartyProductsLoading}
                                    onClick={handleOpenAddCombinedProductModal}
                                >
                                    Add a combined product
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default DistributorProductsPage;
