import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { bindActionCreators } from 'redux';

import { extractFunctions } from '../../utils/React';
import UserContext from '../../context/UserContext';
import * as uiActions from '../../actions/uiActions';
import * as userActions from '../../actions/userActions';
import * as appActions from '../../actions/appActions';
import * as eventsActions from '../../actions/eventsActions';
import * as constantActions from '../../actions/constantActions';

let UserObject = function (user, parties) {
    this.details = user.details;
    this.ticketCount = user.ticketCount;
    this.loggedIn = user.loggedIn;
    this.nodes = user.nodes;
    this.token = user.token;
    this.permissionsByType = user.permissionsByType;
    this.permissionsByNode = user.permissionsByNode;
    this.permissions = user.permissions;
    this.parties = parties;
    this.settings = user.settings;
    this.userGroups = user.userGroups;
    this.loggedInAsUser = user.liau;
};

UserObject.prototype = {
    get fullname() {
        return this.details
            ? `${this.details.firstName} ${this.details.lastName}`
            : null;
    },
    get party() {
        const party = this.details
            ? _.find(this.parties, { id: this.details.partyId })
            : undefined;
        return party ? party : undefined;
    },
    hasPermission(nodeType, permissionType) {
        if (!this.permissionsByType) return false;
        return this.permissionsByType[nodeType]?.[permissionType].length > 0;
    },
    checkPermissionForNode(nodeId, permissionType) {
        const permissions = this.permissionsByNode[nodeId] || [];

        //TODO: Possible Permission Issue, should hav all but doesn't get it

        return permissions.includes(permissionType);
    },
    getPermissionForNode(nodeId) {
        return this.permissionsByNode[nodeId];
    },
};

function UserWrapper(ComponentToWrap) {
    class User extends Component {
        render() {
            const { currentUser, userActions, constantActions } = this.props;

            const values = {
                currentUser,
                userStateActions: userActions,
                constantStateActions: constantActions,
                userActions: extractFunctions(this),
            };
            return (
                <UserContext.Provider value={values}>
                    <ComponentToWrap {...values} {...this.props} />
                </UserContext.Provider>
            );
        }
    }

    User.propTypes = {
        userActions: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        uiActions: PropTypes.object.isRequired,
        constantActions: PropTypes.object.isRequired,
        history: PropTypes.object,
    };

    function mapStateToProps(state) {
        const currentUser = state.currentUser
            ? new UserObject(
                  { ...state.currentUser, ...state.currentUserPermissions },
                  state.parties.list,
              )
            : null;
        return {
            parties: state.parties.list,
            currentUser,
            isMenuExpanded: state.uiStatus.isMenuExpanded,
            appData: state.appData,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            userActions: bindActionCreators(userActions, dispatch),
            uiActions: bindActionCreators(uiActions, dispatch),
            appActions: bindActionCreators(appActions, dispatch),
            eventsActions: bindActionCreators(eventsActions, dispatch),
            constantActions: bindActionCreators(constantActions, dispatch),
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(User);
}

export default UserWrapper;
