export function mapPrincipal(principal) {
    if (!principal || !principal.id) return null;
    return {
        id: principal.id,
        userId: principal.user ? principal.user.id : null,
        firstName: principal.user ? principal.user.firstName : null,
        lastName: principal.user ? principal.user.lastName : null,
        userGroupId: principal.userGroup ? principal.userGroup.id : null,
        userGroupLabel: principal.userGroup ? principal.userGroup.label : null,
        label: principal.label ? principal.label : null,
        type: principal.type ? principal.type : null,
    };
}

export default mapPrincipal;
