import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Placeholder } from 'semantic-ui-react';

import ApplicableDateLabel from '../common/ApplicableDateLabel';
import PartyLabel from '../common/labels/PartyLabel';

import TicketAssignment from './TicketAssignment';
import TicketResolutionButtons from './TicketResolutionButtons';
import TicketResolutionIconsContainer from './TicketResolutionIconsContainer';
import TicketStats from './TicketStats';
import TicketRowStatus from './TicketRowStatus';
import TicketStatus from './TicketStatus';

import {
    applyResolutionLabelOverride,
    getTicketLabel,
} from '../../utils/TicketUtils';
import { WorkflowWrapContext } from '../../context/WorkflowContext';

const TicketRowFull = (props) => {
    const {
        ticket,
        onClick,
        transitioning,
        resolving,
        resolutionClicked,
        transitionClicked,
        currentStatusNode,
        userHasInputPermission,
        onContextMenu,
        showProgramme,
        showTicketSet,
        showApplicableDate,
        showAssignedParty,
        followUp,
        handleFollowUpClick,
    } = props;

    if (!currentStatusNode) {
        return (
            <div className="ticket-row full placeholder">
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            </div>
        );
    }
    const color = ticket.statusNodeColor;
    const {
        statusNodeLabel,
        label,
        attachmentsCount,
        commentsCount,
        statusResolutions,
        executionTicketSetLabel,
    } = ticket;
    const currentTicketLabel = getTicketLabel(
        label,
        showTicketSet,
        executionTicketSetLabel,
    );

    const subheader = (
        <Header.Subheader>
            {showProgramme && <span>{ticket.executionLabel}</span>}
            {showApplicableDate && ticket.executionApplicableDate && (
                <span>
                    {' '}
                    -{' '}
                    <ApplicableDateLabel
                        applicableDate={ticket.executionApplicableDate}
                        applicableDateTypeId={
                            ticket.executionApplicableDateTypeId
                        }
                    />
                </span>
            )}
        </Header.Subheader>
    );

    return (
        <React.Fragment>
            <div className="ticket-row full" onContextMenu={onContextMenu}>
                <TicketRowStatus
                    color={color}
                    followUp={followUp}
                    statusNodeLabel={statusNodeLabel}
                    onFollowUpClick={handleFollowUpClick}
                />
                <div
                    className="ticket-row-header"
                    onClick={() => {
                        onClick(ticket);
                    }}
                >
                    <Header
                        as="h4"
                        content={currentTicketLabel}
                        subheader={subheader}
                    />

                    <TicketStats
                        attachmentsCount={attachmentsCount}
                        commentsCount={commentsCount}
                    />
                </div>

                {showAssignedParty && (
                    <div className="actions">
                        <PartyLabel
                            party={{
                                id: ticket.assignedPartyId,
                                label: ticket.assignedPartyLabel,
                            }}
                        />
                    </div>
                )}
                <div className="actions">
                    <TicketAssignment ticket={ticket} />
                </div>
                <div className="actions">
                    <TicketStatus
                        statusNode={currentStatusNode}
                        color={color}
                        name={currentStatusNode.label}
                    />
                </div>
                {currentStatusNode.status.resolutions.length > 0 ||
                    (statusResolutions.length > 0 && (
                        <div className="actions">
                            {currentStatusNode.status.resolutions.length >
                                0 && (
                                <React.Fragment>
                                    <TicketResolutionButtons
                                        resolutions={applyResolutionLabelOverride(
                                            currentStatusNode.status,
                                            _.get(
                                                ticket,
                                                'meta.resolutionLabels',
                                            ),
                                        )}
                                        statusResolutions={
                                            ticket.statusResolutions
                                        }
                                        onClick={resolutionClicked}
                                        loading={resolving}
                                        singleButton={true}
                                        disabled={!userHasInputPermission}
                                    />
                                </React.Fragment>
                            )}

                            {statusResolutions.length > 0 && (
                                <Header size="tiny">
                                    Resolutions:{' '}
                                    <TicketResolutionIconsContainer
                                        className="resolutions"
                                        statusResolutions={statusResolutions}
                                        disabled={!userHasInputPermission}
                                    />
                                </Header>
                            )}
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
};

TicketRowFull.propTypes = {
    ticket: PropTypes.object,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    transitioning: PropTypes.bool,
    resolving: PropTypes.bool,
    resolutionClicked: PropTypes.func,
    transitionClicked: PropTypes.func,
    workflowActions: PropTypes.object,
    workflow: PropTypes.object,
    currentUser: PropTypes.object,
    currentStatusNode: PropTypes.object,
    userHasInputPermission: PropTypes.bool,
    showProgramme: PropTypes.bool,
    showTicketSet: PropTypes.bool,
    showApplicableDate: PropTypes.bool,
    showAssignedParty: PropTypes.bool,
    onContextMenu: PropTypes.func,
    followUp: PropTypes.bool,
    handleFollowUpClick: PropTypes.func,
};

export default WorkflowWrapContext(TicketRowFull);
