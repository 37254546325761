import { Entity, ExecutionTicketSet } from '@brallum/vidende/src/types';

export const generateProductLabelsFromEntities = (
    productEntities: Entity[],
    ticketSetLabel: string,
) => {
    if (!productEntities.length) {
        return { label: '', productClass: '', UMR: '' };
    }
    const label =
        productEntities.length > 1
            ? `${ticketSetLabel} (${productEntities
                  .map((entity) => entity.label)
                  .join(', ')})`
            : productEntities[0].label;
    const UMR =
        productEntities.length > 1
            ? productEntities
                  .map(
                      (entity) =>
                          entity.entries?.find(
                              (entry) =>
                                  entry.entityTypeFieldId === 'PRODUCT_UMR',
                          )?.value,
                  )
                  .join(', ')
            : productEntities[0].entries?.find(
                  (entry) => entry.entityTypeFieldId === 'PRODUCT_UMR',
              )?.value || '';

    const productClass =
        productEntities.length > 1
            ? Array.from(
                  new Set<any>(
                      productEntities.map(
                          (entity) =>
                              entity.entries?.find(
                                  (entry) =>
                                      entry.entityTypeFieldId ===
                                      'PRODUCT_CLASS',
                              )?.value,
                      ),
                  ),
              ).join(', ')
            : productEntities[0].entries?.find(
                  (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS',
              )?.value || '';

    return { label, productClass, UMR };
};
