/* eslint-disable import/default */

import 'reflect-metadata';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { history } from './store/configureStore';
import storeProvider from './store/storeProvider';
import { PersistGate } from 'redux-persist/integration/react';
import Root from './components/Root';
import errorApi from './api/errorApi';
import './styles/semantic.less';
// import "@neocoast/semantic-ui-less/semantic.less";
// import 'semantic-ui-less/semantic.less';
import './styles/styles.scss';
import './styles/themes.scss';
import 'toastr/build/toastr.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'rc-slider/assets/index.css';
import 'jsoneditor-react/es/editor.min.css';
import { loadModelStructure, loadConfig } from './actions/constantActions';
import * as userActions from './actions/userActions';

interface Test {
    test: string;
}

storeProvider.init(() => {
    initialBootstrap();
});

function renderApp() {
    const { store, persistor } = storeProvider.getStore();
    const container = document.getElementById('app');
    const root = createRoot(container);
    root.render(
            <PersistGate loading={null} persistor={persistor}>
                <Root store={store} history={history} />
            </PersistGate>
    );
}

function initialBootstrap() {
    const { store, persistor } = storeProvider.getStore();
    const currentUser = store.getState().currentUser;
    if (currentUser.loggedIn) {
        //Renew user token to check if not expired
        store
            .dispatch(userActions.renewToken())
            .then(() => {
                //Not expired so get constants
                Promise.all([
                    store.dispatch(loadConfig()),
                    store.dispatch(loadModelStructure()),
                ]).then(() => {
                    renderApp();
                });
            })
            .catch(() => {
                //Token has expired to render app to allow login
                renderApp();
            });
    } else {
        renderApp();
    }

    // if (module.hot) {
    //   module.hot.accept("./components/Root", () => {
    //     const NewRoot = require("./components/Root").default;
    //     render(
    //       <AppContainer>
    //         <PersistGate loading={null} persistor={persistor}>
    //           <NewRoot store={store} history={history} />
    //         </PersistGate>
    //       </AppContainer>,
    //       document.getElementById("app")
    //     );
    //   });
    // }

    window.addEventListener(
        'unhandledrejection',
        function (promiseRejectionEvent) {
            errorApi.saveError(promiseRejectionEvent.reason);
        },
    );

    window.onerror = function (msg, url, lineNo, columnNo, error) {
        errorApi.saveError(error);

        return false;
    };
}
