import React, { useEffect, useState, ChangeEvent, FocusEvent } from 'react';
import { Form, Icon, Popup, SemanticWIDTHS } from 'semantic-ui-react';
import _ from 'lodash';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';
import Outlier from './Outlier';
import { RatingValue } from '../../../api/serverConfigurationApi';

interface TextProps {
    name: string;
    label?: string;
    placeholder?: string;
    value?: string;
    onChange?: (
        event: null,
        data: {
            value: string;
            name: string;
            outlier?: boolean;
            excludeFromStatistics?: boolean;
        },
    ) => void;
    disabled?: boolean;
    type?: string;
    errors?: Record<string, string[]>;
    error?: boolean;
    options?: RatingValue[];
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    fluid?: boolean;
    required?: boolean;
    editable?: boolean;
    debounce?: boolean;
    width?: SemanticWIDTHS;
    outlier?: boolean;
    excludeFromStatistics?: boolean;
    dataField?: boolean;
    isManufacturer?: boolean;
    description?: string;
    readonly?: boolean;
    descriptionOnScreen?: boolean;
}

const Rating = (props: TextProps) => {
    const {
        label,
        placeholder,
        value: propsValue,
        onChange,
        name,
        options,
        type = 'text',
        errors,
        onFocus,
        onBlur,
        fluid,
        required,
        editable = true,
        width,
        debounce = true,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;
    const [value, setValue] = useState<string | undefined>(propsValue);
    const fieldErrors = errors ? errors[name] : undefined;

    const currentOption =
        Array.isArray(options) &&
        options.find((option) => option.id === Number(value));

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    if (!editable) {
        return <React.Fragment>{value}</React.Fragment>;
    }

    return (
        <Form.Field required={required} width={width} error={error}>
            <label>
                {label}
                {!props?.descriptionOnScreen && <FieldToolTip {...props} />}
                {isManufacturer && dataField && (
                    <Outlier
                        value={value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            {props?.descriptionOnScreen && props?.description && (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {props.description}
                </div>
            )}
            <Form.Input
                name={name}
                placeholder={label || placeholder}
                value={currentOption?.label || ''}
                disabled={true}
                className={`readonly ${currentOption?.color || ''}-input`}
                type={type}
                error={!!fieldErrors}
                onFocus={onFocus}
                onBlur={onBlur}
                fluid={fluid}
            />
            {_.isArray(fieldErrors) && <FieldErrors errors={fieldErrors} />}
        </Form.Field>
    );
};

export default Rating;
