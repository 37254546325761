import React, { ReactNode } from 'react';
import usePermissions from '../../utils/hooks/usePermissions';

interface CheckPermissionProps {
    children: ReactNode;
    structuralNodeId?: number;
    permissionType?: string;
    nodeType?: string;
    override?: boolean;
}

const CheckPermission = ({
    structuralNodeId,
    permissionType,
    children,
    nodeType,
    override,
}: CheckPermissionProps) => {
    const { hasPermission } = usePermissions({
        structuralNodeId: structuralNodeId!,
        permissionType,
        nodeType,
    });

     if (override) return <>{children}</>;
    if (!permissionType) return null;
    if (hasPermission) return <>{children}</>;
    return null;
};

export default CheckPermission;
