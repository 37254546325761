import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { APPLICABLE_DATE_TYPES } from '../../utils/ExecutionPlanLaunchConsts';
import {
    Container,
    Dimmer,
    Form,
    Grid,
    Header,
    Loader,
    Segment,
    Table,
    Button,
    Popup,
} from 'semantic-ui-react';
import EntityApi from '../../api/entityApi';
import { ProductInformation } from '../../types';
import toastr from 'toastr';
import Text from '../common/fields/Text';
import TextArea from '../common/fields/TextArea';
import ActionButtons from '../common/ActionButtons';
import * as permissionTypes from '../../constants/permissionTypes';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import UserContext from '../../context/UserContext';
import ConfirmWrapper from '../common/ConfirmWrapper';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import * as uiActions from '../../actions/uiActions';
import { useDispatch } from 'react-redux';
import { deleteEntity } from '../../actions/entityActions';
import { FirstStyleKpiComponent } from '../common/dashboard/components/KpiComponent/FirstStyleKpiComponent';
import KpiComponent from '../common/dashboard/components/KpiComponent/KpiComponent';
import ExecutionPlanApi from '../../api/executionPlanApi';
import TicketApi from '../../api/ticketApi';
import { format, parseISO } from 'date-fns';
import CheckPermission from '../common/CheckPermission';
import FeatureFlag from '../common/FeatureFlag';
import '../common/ActionButtons.scss';
import { useAppSelector } from '../../actions/store';

const isValidUrl = (urlString: string): boolean => {
    try {
        new URL(urlString);
        return true;
    } catch (e) {
        return false;
    }
};

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState<ProductInformation>();
    const { id: productId } = useParams<{ id?: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const config = useAppSelector((state) => state.constants.options);

    const userContext = useContext(UserContext);
    const modalContext = useContext(ConfirmationModalContext);

    const { currentUser } = userContext;

    const fetchProduct = async (id: string) => {
        try {
            setIsLoading(true);
            const res = await EntityApi.getProductInformation(id, {
                include: [
                    {
                        relation: 'productSubjectivities',
                        scope: {
                            order: 'sort ASC',
                        },
                    },
                    {
                        relation: 'entityAttachments',
                        scope: {
                            order: 'attachedTo ASC, fileName ASC',
                        },
                    },
                ],
            });
            console.log(res);
            setProduct(res);
            setIsLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (productId) {
            fetchProduct(productId);
        } else history.push('products');
    }, [productId]);

    const handleNotifyFairValueComplete = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will email all distributors that have submitted a PVIE for this product so that they can log in an see the fair value assessment. Do you want to continue?`,
            notifyFairValueCompleteOnConfirm,
        );
    };
    const notifyFairValueCompleteOnConfirm = async () => {
        try {
            await EntityApi.notifyFairValueComplete(product.id);
            if (fetchProduct) fetchProduct(productId);
        } catch (error) {}
    };

    const handleNotifyNewProductAdded = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will notify all distributors that have logged into the system that a new product has been added. Do you want to continue?`,
            notifyNewProductAddedOnConfirm,
        );
    };
    const notifyNewProductAddedOnConfirm = async () => {
        try {
            await EntityApi.notifyNewProductAdded(product.id);
            if (fetchProduct) fetchProduct(productId);
        } catch (error) {}
    };

    const handleRestartFairValue = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will notify all distributors that have previously submitted a PVIE that they need to update it. Do you want to continue?`,
            RestartFairValueOnConfirm,
        );
    };

    const RestartFairValueOnConfirm = () => {
        console.log('restartFairValue');
    };

    const handleRestartProgramme = (
        programmeLabel: string,
        ticketId: number,
    ) => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `This will Restart the ${programmeLabel}. Do you want to continue?`,
            () => RestartProgrammeOnConfirm(ticketId),
        );
    };

    const RestartProgrammeOnConfirm = async (ticketId: number) => {
        console.log('restartFairValue');
        await TicketApi.resetTicketById(ticketId);
        if (fetchProduct) fetchProduct(productId);
    };

    const handleDeleteProduct = () => {
        modalContext.confirmPopupActions.showConfirmDialog(
            `Are you sure you want to delete ${product.label} product?`,
            deleteProductOnConfirm,
        );
    };
    const deleteProductOnConfirm = async () => {
        try {
            const productToDelete = await EntityApi.getEntity(product.id);
            await dispatch(deleteEntity(productToDelete));
        } catch (error) {
            toastr.error(error);
        }
    };

    const launchProgramme = async (programmeExecutionPlanId: number) => {
        if (!programmeExecutionPlanId) {
            return toastr.error('Cannot launch. Contact administrator.');
        }
        if (!product.productOwnerId) {
            return toastr.error('Cannot launch without an owner.');
        }
        if (!product.productReviewerId) {
            return toastr.error('Cannot launch without a reviewer.');
        }

        setIsLoading(true);

        try {
            await ExecutionPlanApi.launchNowExecutionPlan(
                programmeExecutionPlanId,
                {
                    entityId: product.id,
                    assignedPartyUserPrincipalId: product.productOwnerId,
                    ownerPartyUserPrincipalId: product.productReviewerId,
                    reviewPartyUserPrincipalId: product.productReviewerId,
                    autoTransition: true,
                },
            );

            setIsLoading(false);
            fetchProduct(productId);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    const launchProgrammeNoTransition = async (
        programmeExecutionPlanId: number,
    ) => {
        if (!programmeExecutionPlanId) {
            return toastr.error('Cannot launch. Contact administrator.');
        }
        if (!product.productOwnerId) {
            return toastr.error('Cannot launch without an owner.');
        }
        if (!product.productReviewerId) {
            return toastr.error('Cannot launch without a reviewer.');
        }

        setIsLoading(true);

        try {
            await ExecutionPlanApi.launchNowExecutionPlan(
                programmeExecutionPlanId,
                {
                    entityId: product.id,
                    assignedPartyUserPrincipalId: product.productOwnerId,
                    ownerPartyUserPrincipalId: product.productReviewerId,
                    reviewPartyUserPrincipalId: product.productReviewerId,
                    autoTransition: false,
                },
            );

            setIsLoading(false);
            fetchProduct(productId);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    //TODO: Sort the popup
    /*
    const launchGovernanceProgramme = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.LAUNCH_GOVERNANCE_PROGRAMME_MODAL,
                modalProps: {
                    mode: 'individual',
                    product,
                },
            }),
        );
    };
*/
    const launchGovernanceProgramme = async (
        programmeExecutionPlanId: number,
    ) => {
        console.log(product);

        if (!programmeExecutionPlanId) {
            return toastr.error('Cannot launch. Contact administrator.');
        }
        if (!product.productOwnerId) {
            return toastr.error('Cannot launch without an owner.');
        }
        if (!product.productReviewerId) {
            return toastr.error('Cannot launch without a reviewer.');
        }

        //How do we pass in the Program Id props? Default has changed
        /*
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.LAUNCH_GOVERNANCE_PROGRAMME_MODAL,
                modalProps: {
                    mode: 'individual',
                    product,
                },
            }),
        );*/

        //Need this instead of the above
        setIsLoading(true);

        try {
            const x = await ExecutionPlanApi.launchNowExecutionPlan(
                programmeExecutionPlanId,
                {
                    entityId: product.id,
                    assignedPartyUserPrincipalId: product.productOwnerId,
                    ownerPartyUserPrincipalId: product.productReviewerId,
                    reviewPartyUserPrincipalId: product.productReviewerId,
                    //autoTransition: true,
                    applicableDate: new Date(),
                    applicableDateTypeId: APPLICABLE_DATE_TYPES.YEAR,
                },
            );

            // Get fresh product data
            const updatedProduct = await EntityApi.getProductInformation(
                product.id,
            );

            // Use the updated product data directly
            await TicketApi.transitionTicketById(
                updatedProduct.governanceProgrammeTicketId,
                updatedProduct.governanceProgrammeTransitionId,
            );

            setIsLoading(false);
            //Reload with Status
            fetchProduct(productId);

            //Redirect to the new Governance Programme
            history.push(`/ticket/${updatedProduct.governanceProgrammeTicketId}`);

        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    const editProduct = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_ENTITY_MODAL,
                modalProps: {
                    entityId: product.id,
                    onSave: () => fetchProduct(productId),
                },
            }),
        );
    };

    const viewClickedExecution = (executionId: number) => {
        history.push(`/execution/${executionId}`);
    };

    const handleMILinkClicked = () => {
        if (config.cdMIUrl && isValidUrl(config.cdMIUrl)) {
            history.push(config.cdMIUrl);
        } else {
            toastr.error('No MI URL Configured');
        }
    };

    const handleProductDistributorsClicked = () => {
        history.push(`/product/${product?.id}/ProductDistributorsPage`);
    };

    const handleAddCoverHolderAndCoManufacturer = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_COVERHOLDER_AND_COMANUFACTURER,
                modalProps: {
                    productInformation: product,
                },
            }),
        );
    };

    const viewClickedTicket = (ticketId: number) => {
        history.push(`/ticket/${ticketId}`);
    };

    const buttonClassName = 'dashboard-action-buttons';
    const buttonSize = 'medium';
    const buttonColor = 'blue';

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">{product?.label} Summary</Header>
            <Grid className="ui equal width grid vidende-dashboard">
                <Grid.Row stretched className="dashboard-row">
                    {product?.manufacturerProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <KpiComponent
                                    color={product?.manufacturerProgrammeColor}
                                    style={1}
                                    value={product.manufacturerProgrammeStatus}
                                    header={product.manufacturerProgrammeHeader}
                                />
                                <ActionButtons
                                    className="dashboard-action-buttons"
                                    size="medium"
                                    launchGovernanceProgrammeClicked={
                                        !product?.manufacturerProgrammeLaunched &&
                                        (() =>
                                            launchProgrammeNoTransition(
                                                product?.manufacturerProgrammeExecutionPlanId,
                                            ))
                                    }
                                    launchGovernanceProgrammeTooltip={
                                        !product?.manufacturerProgrammeLaunched &&
                                        `Launch ${product?.manufacturerProgrammeHeader}`
                                    }
                                    viewClicked={
                                        product?.manufacturerProgrammeLaunched &&
                                        (() =>
                                            viewClickedExecution(
                                                product?.manufacturerProgrammeExecutionId,
                                            ))
                                    }
                                    viewTooltip={
                                        product?.manufacturerProgrammeLaunched &&
                                        `Go to ${product?.manufacturerProgrammeHeader}`
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productRiskAssessmentProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.productRiskAssessmentProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.productRiskAssessmentProgrammeStatus
                                        }
                                        header={
                                            product?.productRiskAssessmentProgrammeHeader
                                        }
                                    />
                                </div>

                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.productRiskAssessmentProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartProgramme(
                                                                    product?.productRiskAssessmentProgrammeHeader,
                                                                    product?.productRiskAssessmentProgrammeTicketId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.productRiskAssessmentProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.productRiskAssessmentProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.productRiskAssessmentProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to ${product?.productRiskAssessmentProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {!product?.productRiskAssessmentProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchProgramme(
                                                                    product?.productRiskAssessmentProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch ${product?.productRiskAssessmentProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome1ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.productOutcome1ProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.productOutcome1ProgrammeStatus
                                        }
                                        header={
                                            product?.productOutcome1ProgrammeHeader
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.productOutcome1ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartProgramme(
                                                                    product?.productOutcome1ProgrammeHeader,
                                                                    product?.productOutcome1ProgrammeTicketId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.productOutcome1ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.productOutcome1ProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.productOutcome1ProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to ${product?.productOutcome1ProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {!product?.productOutcome1ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.ADMIN
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchProgramme(
                                                                    product?.productOutcome1ProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch ${product?.productOutcome1ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome2ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.productOutcome2ProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.productOutcome2ProgrammeStatus
                                        }
                                        header={
                                            product?.productOutcome2ProgrammeHeader
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.productOutcome2ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartProgramme(
                                                                    product?.productOutcome2ProgrammeHeader,
                                                                    product?.productOutcome2ProgrammeTicketId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.productOutcome2ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.productOutcome2ProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.productOutcome2ProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to ${product?.productOutcome2ProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {!product?.productOutcome2ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.ADMIN
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchProgramme(
                                                                    product?.productOutcome2ProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch ${product?.productOutcome2ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row>
                    {product?.productOutcome3ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.productOutcome3ProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.productOutcome3ProgrammeStatus
                                        }
                                        header={
                                            product?.productOutcome3ProgrammeHeader
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.productOutcome3ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartProgramme(
                                                                    product?.productOutcome3ProgrammeHeader,
                                                                    product?.productOutcome3ProgrammeTicketId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.productOutcome3ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.productOutcome3ProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.productOutcome3ProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to ${product?.productOutcome3ProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {!product?.productOutcome3ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.ADMIN
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchProgramme(
                                                                    product?.productOutcome3ProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch ${product?.productOutcome3ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.productOutcome4ProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.productOutcome4ProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.productOutcome4ProgrammeStatus
                                        }
                                        header={
                                            product?.productOutcome4ProgrammeHeader
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.productOutcome4ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartProgramme(
                                                                    product?.productOutcome4ProgrammeHeader,
                                                                    product?.productOutcome4ProgrammeTicketId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.productOutcome4ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.productOutcome4ProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.productOutcome4ProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to ${product?.productOutcome4ProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {!product?.productOutcome4ProgrammeLaunched && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.ADMIN
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchProgramme(
                                                                    product?.productOutcome4ProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch ${product?.productOutcome4ProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.fairValueVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={product?.fairValueColor}
                                        style={1}
                                        value={product?.fairValueStatus}
                                        header={product?.fairValueHeader}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.newProductEmailSent &&
                                            product?.fairValueRequired && (
                                                <CheckPermission
                                                    structuralNodeId={
                                                        product?.structuralNodeId
                                                    }
                                                    permissionType={
                                                        permissionTypes.REVIEW
                                                    }
                                                >
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                basic
                                                                color={
                                                                    buttonColor
                                                                }
                                                                icon="email"
                                                                onClick={() => {
                                                                    handleNotifyNewProductAdded();
                                                                }}
                                                            />
                                                        }
                                                    >
                                                        {`Notify ${product?.label} is available for Fair Value`}
                                                    </Popup>
                                                </CheckPermission>
                                            )}
                                        {product?.fairValueRequired && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="redo"
                                                            onClick={() => {
                                                                handleRestartFairValue();
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Restart ${product?.fairValueHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                        {product?.fairValueRequired &&
                                            product?.fairValueLaunched && (
                                                <CheckPermission
                                                    structuralNodeId={
                                                        product?.structuralNodeId
                                                    }
                                                    permissionType={
                                                        permissionTypes.REVIEW
                                                    }
                                                >
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                basic
                                                                color={
                                                                    buttonColor
                                                                }
                                                                icon="rocket"
                                                                onClick={() => {
                                                                    handleNotifyFairValueComplete();
                                                                }}
                                                            />
                                                        }
                                                    >
                                                        {`Notify Distributors ${product?.fairValueHeader} is complete`}
                                                    </Popup>
                                                </CheckPermission>
                                            )}
                                        <Popup
                                            trigger={
                                                <Button
                                                    basic
                                                    color={buttonColor}
                                                    icon="factory"
                                                    onClick={() => {
                                                        handleProductDistributorsClicked();
                                                    }}
                                                />
                                            }
                                        >
                                            {'Distributors of this Product'}
                                        </Popup>                                            
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                    {product?.governanceProgrammeVisible && (
                        <Grid.Column>
                            <Segment className="dashboard-widget">
                                <div>
                                    <KpiComponent
                                        color={
                                            product?.governanceProgrammeColor
                                        }
                                        style={1}
                                        value={
                                            product?.governanceProgrammeStatus
                                        }
                                        header={
                                            product?.governanceProgrammeHeader
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button.Group
                                        size={buttonSize}
                                        className={`${buttonClassName} hide-for-print action-buttons`}
                                    >
                                        {product?.governanceProgrammeLaunched && (
                                            <Popup
                                                trigger={
                                                    <Button
                                                        basic
                                                        color={buttonColor}
                                                        icon="eye"
                                                        onClick={() => {
                                                            viewClickedTicket(
                                                                product?.governanceProgrammeTicketId,
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                {`Go to latest ${product?.governanceProgrammeHeader}`}
                                            </Popup>
                                        )}
                                        {product?.governanceProgrammeLaunchVisible && (
                                            <CheckPermission
                                                structuralNodeId={
                                                    product?.structuralNodeId
                                                }
                                                permissionType={
                                                    permissionTypes.REVIEW
                                                }
                                            >
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            basic
                                                            color={buttonColor}
                                                            icon="rocket"
                                                            onClick={() => {
                                                                launchGovernanceProgramme(
                                                                    product?.governanceProgrammeExecutionPlanId,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {`Launch New ${product?.governanceProgrammeHeader}`}
                                                </Popup>
                                            </CheckPermission>
                                        )}
                                    </Button.Group>
                                </div>
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            <Segment>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Text
                                label="Product Owner"
                                name="productOwner"
                                value={product?.productOwner}
                                readonly={true}
                                placeholder=""
                            />
                        </Form.Field>
                        <Form.Field>
                            <Text
                                label="Product Reviewer"
                                name="productReviewer"
                                value={product?.productReviewer}
                                readonly={true}
                                placeholder=""
                            />
                        </Form.Field>
                    </Form.Group>
                    <FeatureFlag
                        featureFlag="VisRiskConsumerDuty"
                        showHide={false}
                    >
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Text
                                    label="Reference"
                                    name="reference"
                                    value={product?.reference}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                            <Form.Field>
                                <Text
                                    label="FCA Category"
                                    name="fcaCategory"
                                    value={product?.fcaCategory}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                    </FeatureFlag>
                    <FeatureFlag
                        featureFlag="VisRiskConsumerDuty"
                        showHide={true}
                    >
                        <Form.Group widths="equal">
                            <Form.Field>
                                <div
                                    style={{
                                        backgroundColor:
                                            product?.riskRatingColor ||
                                            'transparent',
                                        padding: '0.5em',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <Text
                                        label="Risk Rating"
                                        name="riskRatingLabel"
                                        value={product?.riskRatingLabel}
                                        readonly={true}
                                        placeholder=""
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <Text
                                    label="Additional Reviewer"
                                    name="productAdditionalReviewer"
                                    value={product?.productAdditionalReviewer}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Text
                                    label="FCA Category"
                                    name="fcaCategory"
                                    value={product?.fcaCategory}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>

                            <Form.Field>
                                <Text
                                    label="Last Review Date"
                                    name="lastReviewDate"
                                    value={
                                        product?.lastReviewDate
                                            ? format(
                                                  product.lastReviewDate,
                                                  'dd/MM/yyyy',
                                              )
                                            : ''
                                    }
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <FeatureFlag
                                featureFlag="Client1Specific"
                                showHide={true}
                            >
                                <Form.Field width={5}>
                                    <Text
                                        label="Reference"
                                        name="reference"
                                        value={product?.id}
                                        readonly={true}
                                        placeholder=""
                                    />
                                </Form.Field>
                            </FeatureFlag>
                            <FeatureFlag
                                featureFlag="Client1Specific"
                                showHide={false}
                            >
                                <Form.Field width={5}>
                                    <Text
                                        label="Reference"
                                        name="reference"
                                        value={product?.reference}
                                        readonly={true}
                                        placeholder=""
                                    />
                                </Form.Field>
                            </FeatureFlag>
                            <Form.Field width={11}>
                                <Text
                                    label="Countries"
                                    name="country"
                                    value={product?.country}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <Text
                                    label="Legal Entities"
                                    name="legalentities"
                                    value={product?.legalEntity}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                    </FeatureFlag>
                    <Form.Group>
                        <Form.Field width={16}>
                            <Text
                                label="Class"
                                name="class"
                                value={product?.classOfBusiness}
                                readonly={true}
                                placeholder=""
                            />
                        </Form.Field>
                    </Form.Group>
                    <FeatureFlag
                        featureFlag="VisRiskConsumerDuty"
                        showHide={true}
                    >
                        <Form.Group widths="equal">
                            <Form.Field>
                                <TextArea
                                    label="Core Coverage & Exclusions"
                                    name="coreInsurance"
                                    value={product?.coreInsurance}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                            <Form.Field>
                                <TextArea
                                    label="Services"
                                    name="additionalServices"
                                    value={product?.additionalServices}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <TextArea
                                    label="Target Market"
                                    name="targetMarket"
                                    value={product?.targetMarket}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                            <Form.Field>
                                <TextArea
                                    label="Groups Product is not suitable for"
                                    name="groupNotSuitable"
                                    value={product?.groupNotSuitable}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <TextArea
                                    label="Product Services Conclusion"
                                    name="conclusionProductServices"
                                    value={product?.conclusionProductServices}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                            <Form.Field>
                                <TextArea
                                    label="Price & Value Conclusion"
                                    name="conclusionPriceValue"
                                    value={product?.conclusionPriceValue}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <TextArea
                                    label="Customer Understanding Conclusion"
                                    name="conclusionCustomerUnderstanding"
                                    value={
                                        product?.conclusionCustomerUnderstanding
                                    }
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                            <Form.Field>
                                <TextArea
                                    label="Consumer Support Conclusion"
                                    name="conclusionConsumerSupport"
                                    value={product?.conclusionConsumerSupport}
                                    readonly={true}
                                    placeholder=""
                                />
                            </Form.Field>
                        </Form.Group>
                    </FeatureFlag>
                    <Form.Field>
                        <TextArea
                            label="Notes"
                            name="notes"
                            value={product?.productNotes}
                            readonly={true}
                            placeholder=""
                        />
                    </Form.Field>
                </Form>
            </Segment>
            <FeatureFlag featureFlag="VisRiskConsumerDuty" showHide={true}>
                <Segment>
                    <div>
                        <strong>Subjectivities</strong>
                    </div>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Subject To</Table.HeaderCell>
                                <Table.HeaderCell>Due Date</Table.HeaderCell>
                                <Table.HeaderCell>Priority</Table.HeaderCell>
                                <Table.HeaderCell>Assigned To</Table.HeaderCell>
                                <Table.HeaderCell>
                                    Current Status
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {product?.productSubjectivities.map(
                                (subjectivity) => (
                                    <Table.Row key={subjectivity.id}>
                                        <Table.Cell>
                                            {subjectivity.link ? (
                                                <Link to={subjectivity.link}>
                                                    {subjectivity.subjectTo}
                                                </Link>
                                            ) : (
                                                subjectivity.subjectTo
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {subjectivity.dueDate
                                                ? format(
                                                      subjectivity.dueDate,
                                                      'dd/MM/yyyy',
                                                  )
                                                : ''}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {subjectivity.priority}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {subjectivity.assignedTo}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {subjectivity.currentStatus}
                                        </Table.Cell>
                                    </Table.Row>
                                ),
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
            </FeatureFlag>
            <Segment>
                <div>
                    <strong>Attachments</strong>
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>File Name</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {product?.entityAttachments.map((attachment) => (
                            <Table.Row key={attachment.id}>
                                <Table.Cell>
                                    {attachment.link ? (
                                        <Link to={attachment.link}>
                                            {attachment.attachedTo}
                                        </Link>
                                    ) : (
                                        attachment.attachedTo
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {attachment.fileName}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
            <CheckPermission
                structuralNodeId={product?.structuralNodeId}
                permissionType={permissionTypes.REVIEW}
            >
                <Segment>
                    <div>
                        <strong>Actions</strong>
                    </div>
                    <Button.Group size="mini">
                        <Popup
                            trigger={
                                <Button
                                    basic
                                    color={buttonColor}
                                    icon="database"
                                    onClick={() => {
                                        handleMILinkClicked();
                                    }}
                                />
                            }
                        >
                            {'MI and Reporting'}
                        </Popup>
                        <CheckPermission
                            structuralNodeId={product?.structuralNodeId}
                            permissionType={permissionTypes.REVIEW}
                        >
                            <Popup
                                trigger={
                                    <Button
                                        basic
                                        color={buttonColor}
                                        icon="pencil"
                                        onClick={() => {
                                            editProduct();
                                        }}
                                    />
                                }
                            >
                                {'Edit Product'}
                            </Popup>
                        </CheckPermission>
                        <CheckPermission
                            structuralNodeId={product?.structuralNodeId}
                            permissionType={permissionTypes.REVIEW}
                        >
                            <Popup
                                trigger={
                                    <Button
                                        basic
                                        color={buttonColor}
                                        icon="exchange"
                                        onClick={() => {
                                            handleAddCoverHolderAndCoManufacturer();
                                        }}
                                    />
                                }
                            >
                                {'Manage Coverholders and CoManufacturers'}
                            </Popup>
                        </CheckPermission>
                        <CheckPermission
                            structuralNodeId={product?.structuralNodeId}
                            permissionType={permissionTypes.SUPER}
                        >
                            <Popup
                                trigger={
                                    <Button
                                        basic
                                        color={buttonColor}
                                        icon="trash alternate outline"
                                        onClick={() => {
                                            handleDeleteProduct();
                                        }}
                                    />
                                }
                            >
                                {'Delete Product'}
                            </Popup>
                        </CheckPermission>
                    </Button.Group>
                </Segment>
            </CheckPermission>
        </Container>
    );
};

export default ConfirmWrapper(ProductPage);
