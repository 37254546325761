import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import * as ticketActions from '../../actions/ticketActions';
import ticketApi from '../../api/ticketApi';
import TicketContext from '../../context/TicketContext';
import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { SIDEBAR_COMPONENTS } from '../common/SidebarRoot';
import * as MODELS from '../../constants/models';
import { getOrderedTicketsFromTicketSets } from '../../utils/TicketsUtils';
import WorkflowWrapper from '../workflow/Workflow';
import ConfirmWrapper from '../common/ConfirmWrapper';
import * as _ from 'lodash';
import moment from 'moment';
import toastr from 'toastr';
import {
    checkUserInputPermission,
    validateTransition,
} from '../../utils/TicketUtils';
import UserWrapper from '../user/User'; // Adjust this import based on your root reducer location
import { RootState } from '../../actions/store';
import { bindActionCreators } from 'redux';
import usePermissions from '../../utils/hooks/usePermissions';
import { Confirm } from 'semantic-ui-react';

interface TicketWrapperProps {
    history: any;
    currentUser: any;
    fullPageView?: boolean;
    ticketId?: number;
    workflow?: any;
    workflows?: any;
    uiActions: typeof uiActions;
    ticketStateActions: typeof ticketActions;
    workflowActions: any;
    tickets: { [key: string]: any };
    maxFileSize: number;
    attachmentsConstants: {
        attachmentClassification: any;
        attachmentType: any;
    };
    showConfirmDialog: (content: string, onConfirm: () => void) => void;
}

const TicketWrapper = (ComponentToWrap: React.ComponentType<any>) => {
    const WrappedComponent = (props: TicketWrapperProps) => {
        const {
            uiActions,
            ticketStateActions,
            history,
            tickets,
            currentUser,
            maxFileSize,
            attachmentsConstants,
            fullPageView,
            workflowActions,
            workflows,
        } = props;
        const [state, setState] = useState({
            currentModal: '',
            modalConfig: {},
            isTicketLoading: false,
            transitioning: false,
            resolving: false,
            recommendationOverride: '',
            transitionErrors: [] as string[],
            saved: false,
            id: undefined as number | undefined,
            showConfirm: false,
            confirmContent: '',
            confirmRedirectUrl: '',
        });

        const { confirmPopupActions } = React.useContext(ConfirmationModalContext);

        const ticket = useMemo(() => {
            return state.id && tickets[state.id]
                ? tickets[state.id]
                : undefined;
        }, [state.id, tickets]);

        const { hasPermission, permissions } = usePermissions({
            structuralNodeId: ticket?.structuralNodeId,
            permissionType: 'p/view',
        });

        const gotoTicket = (ticket: any) => {
            history.push(`/ticket/${ticket.id}`);
        };

        const loadTickets = (ticketSets: any) => {
            const ticketsFromSets = getOrderedTicketsFromTicketSets(ticketSets);
            const ticketsToLoad = ticketsFromSets.filter(
                (ticket) => !tickets[ticket],
            );

            ticketStateActions.loadTickets({ tickets: ticketsToLoad });
        };

        const setTicket = ({
            id,
            isPreview = true,
            autoLoad = true,
        }: {
            id: number;
            isPreview?: boolean;
            autoLoad?: boolean;
        }) => {
            if (id === state.id) return;

            setState((prevState) => ({ ...prevState, id }));
            const currentTicket = tickets[id];
            if (
                (autoLoad && !currentTicket) ||
                (!isPreview && currentTicket?.isPreview)
            ) {
                setState((prevState) => ({
                    ...prevState,
                    isTicketLoading: true,
                }));
                const action = ticketStateActions.loadTicket({
                    id,
                    isPreview,
                }) as any;
                action.then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        isTicketLoading: false,
                    }));
                });
            } else if (currentTicket && autoLoad) {
                ticketApi.getSimpleTicket({ id }).then((ticket) => {
                    const isUpdatedOnServer = moment(ticket.modifiedAt).isAfter(
                        currentTicket.modifiedAt,
                    );
                    if (isUpdatedOnServer) {
                        setState((prevState) => ({
                            ...prevState,
                            isTicketLoading: true,
                        }));
                        const action = ticketStateActions.loadTicket({
                            id,
                            isPreview,
                        }) as any;
                        action.then(() => {
                            setState((prevState) => ({
                                ...prevState,
                                isTicketLoading: false,
                            }));
                        });
                    }
                });
            }
        };

        const resolutionClicked = (
            event: React.MouseEvent,
            resolution: any,
        ) => {
            setState((prevState) => ({ ...prevState, resolving: true }));
            const { statusId, id } = resolution;

            const action = ticketStateActions.resolve(
                ticket,
                statusId,
                id,
                resolution,
            ) as any;
            action
                .then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        resolving: false,
                    }));
                })
                .catch((error: any) => {
                    toastr.error(error);
                    setState((prevState) => ({
                        ...prevState,
                        resolving: false,
                        error,
                    }));
                });
        };

        const transitionClicked = (
            event: React.MouseEvent,
            transition: any,
        ) => {
            const currentStatusNode = ticket
                ? workflowActions.getStatusNode(ticket.statusNodeId)
                : null;
            const validate = validateTransition(
                ticket,
                transition,
                currentStatusNode,
            );
            if (typeof validate !== 'boolean' && validate.errors) {
                toastr.error(
                    validate.errors
                        .map((error) => `${error.label}<br/>`)
                        .join(''),
                );
                setState((prevState) => ({
                    ...prevState,
                    transitionErrors:
                        Array.isArray(validate.errors) &&
                        validate.errors.map(
                            (error) =>
                                `${error.label} ${error.description || ''}`,
                        ),
                }));
                return;
            }

            setState((prevState) => ({
                ...prevState,
                transitioning: true,
                transitionErrors: [],
            }));
            if (ticket?.edited) {
                checkEditedFields(transition);
            } else {
                transitionTicket(transition);
            }
        };

        const saveWorkflowFields = () => {
            return ticketStateActions.saveWorkflowFields(ticket);
        };

        const checkEditedFields = (transition: any) => {
            const currentUserDetails = currentUser.details;
            const currentTicket = ticket;
            if (currentTicket) {
                if (
                    _.every(['entries', 'workflowEntries'], (el) =>
                        _.includes(currentTicket.edited, el),
                    )
                ) {
                    const action = ticketStateActions.saveActivityFields(
                        currentTicket,
                        currentUserDetails,
                    ) as any;
                    action.then(() => {
                        const action2 = ticketStateActions.saveWorkflowFields(
                            currentTicket,
                        ) as any;
                        action2.then(() => {
                            setState((prevState) => ({
                                ...prevState,
                                saved: true,
                            }));
                            transitionTicket(transition);
                        });
                    });
                } else {
                    if (_.includes(currentTicket.edited, 'entries')) {
                        const action = ticketStateActions.saveActivityFields(
                            currentTicket,
                            currentUserDetails,
                        ) as any;
                        action.then(() => {
                            setState((prevState) => ({
                                ...prevState,
                                saved: true,
                            }));
                            transitionTicket(transition);
                        });
                    } else if (
                        _.includes(currentTicket.edited, 'workflowEntries')
                    ) {
                        const action = ticketStateActions.saveWorkflowFields(
                            currentTicket,
                        ) as any;
                        action.then(() => {
                            setState((prevState) => ({
                                ...prevState,
                                saved: true,
                            }));
                            transitionTicket(transition);
                        });
                    }
                }
            }
        };

        const transitionTicket = (transition: any) => {
            const action = ticketStateActions.transition(
                ticket,
                transition.id,
            ) as any;
            action
                .then((transitionResponse: any) => {
                    console.log('transitionResponse', transitionResponse);
                    console.log('Redirect URL', transitionResponse.result?.redirectUrl);
                
                    if (transitionResponse.result?.redirectUrl) {
                        if (transitionResponse.result?.redirectUrlText && transitionResponse.result?.redirectUrlText.length > 1) {
                            confirmPopupActions.showConfirmDialog(
                                transitionResponse.result.redirectUrlText,
                                () => {
                                    window.location.href = transitionResponse.result.redirectUrl;
                                }
                            );
                        } else {
                            window.location.href = transitionResponse.result.redirectUrl;
                        }
                    }

                    setState((prevState) => ({
                        ...prevState,
                        transitioning: false,
                    }));
                })
                .catch((error: any) => {
                    toastr.error(error);
                    setState((prevState) => ({
                        ...prevState,
                        transitioning: false,
                        error,
                    }));
                    console.error(error);
                });
        };

        const openAssignTicketsModal = (
            assignObject: any,
            isTicketSet = true,
        ) => {
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ASSIGN_TICKET_MODAL,
                modalProps: {
                    assignObject,
                    isTicketSet,
                },
            });
        };

        const openAttachment = (ticket: any, attachment: any) => {
            ticketApi.openAttachment(ticket, attachment.id);
        };

        const openMoreInfoSidebar = (
            ticket: any,
            handleReadAll: any,
            isReadAll: boolean,
        ) => {
            uiActions.showSidebar({
                sidebarType: SIDEBAR_COMPONENTS.MORE_INFO_SIDEBAR,
                sidebarProps: { ticket, handleReadAll, isReadAll },
                sidebarConfig: { width: 'wide' },
            });
        };

        const openAddAttachmentModal = (ticket: any) => {
            const { details } = currentUser;
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.ADD_EXISTING_ATTACHMENT,
                modalProps: {
                    currentModel: ticket,
                    modelStateActions: ticketStateActions,
                    modelType: MODELS.EXECUTION_TICKETS,
                    maxFileSize,
                    currentUser: details,
                },
            });
        };

        const openEditAttachmentDetailsModal = (attachment: any) => {
            const userDetails = currentUser.details;
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.EDIT_ATTACHMENT_DETAILS,
                modalProps: {
                    attachment,
                    userDetails,
                    ticketId: state.id,
                },
            });
        };

        const values = useMemo(() => {
            const currentStatusNode = ticket
                ? workflowActions.getStatusNode(ticket.statusNodeId)
                : null;
            let currentDeadlineDate, currentStatusOverdue;

            if (currentStatusNode) {
                const { statusId } = currentStatusNode;
                const { workflowId, deadlines } = ticket;
                const currentWorkflow = workflows.byId[workflowId];
                const { statuses } = currentWorkflow;
                const orderedStatuses = _.orderBy(
                    statuses,
                    (status) => status.order,
                );
                const currentStatusOrder =
                    _.findIndex(
                        orderedStatuses,
                        (status) => status.id === statusId,
                    ) + 1;

                const nextStatuses = _.filter(
                    orderedStatuses,
                    (status) => status.order > currentStatusOrder,
                );
                if (nextStatuses.length) {
                    const nextStatusesFromDeadline = _.filter(
                        nextStatuses,
                        (nextStatus) =>
                            _.find(
                                deadlines,
                                (deadline) =>
                                    nextStatus.id === deadline.workflowStatusId,
                            ),
                    );
                    const nextDeadlineStatus = _.first(
                        nextStatusesFromDeadline,
                    );
                    if (nextDeadlineStatus) {
                        const nextDeadline = _.find(
                            deadlines,
                            (deadline) =>
                                deadline.workflowStatusId ===
                                nextDeadlineStatus.id,
                        );
                        currentDeadlineDate = nextDeadline
                            ? nextDeadline.deadlineDate
                            : null;
                        currentStatusOverdue =
                            moment().diff(moment(currentDeadlineDate)) > 0;
                    }
                }
            }

            const dashboardId = _.get(ticket, 'activity.meta.dashboardId');
            const currentFields = _.has(ticket, 'activity')
                ? _.filter(
                      ticket.activity.fields,
                      ({ workflowStatusInputTypeId }) =>
                          _.isNull(workflowStatusInputTypeId),
                  )
                : [];
            const allowDataEntry = currentStatusNode
                ? currentStatusNode.status.allowDataEntry
                : false;

            const userHasInputPermission = checkUserInputPermission(
                currentStatusNode,
                permissions,
            );
            const userHasAssignPermission =
                userHasInputPermission ||
                (ticket &&
                    ticket.assignedPrincipalId ===
                        currentUser.details.principalId);

            return {
                ticket,
                currentTicket: ticket,
                attachmentsConstants,
                ticketActions: extractFunctions({
                    gotoTicket,
                    loadTickets,
                    setTicket,
                    resolutionClicked,
                    transitionClicked,
                    saveWorkflowFields,
                    checkEditedFields,
                    transitionTicket,
                    openAssignTicketsModal,
                    openAttachment,
                    openMoreInfoSidebar,
                    openAddAttachmentModal,
                    openEditAttachmentDetailsModal,
                }),
                ticketStateActions,
                isTicketLoading: state.isTicketLoading,
                resolving: state.resolving,
                transitioning: state.transitioning,
                fullPageView,
                currentStatusNode,
                currentDeadlineDate,
                currentStatusOverdue,
                dashboardId,
                currentFields,
                allowDataEntry,
                userHasInputPermission,
                userHasAssignPermission,
                transitionErrors: state.transitionErrors,
            };
        }, [
            ticket,
            currentUser,
            ticketStateActions,
            state.isTicketLoading,
            state.resolving,
            state.transitioning,
            fullPageView,
            workflowActions,
            workflows,
            attachmentsConstants,
            state.transitionErrors,
            permissions,
        ]);

        return (
            <TicketContext.Provider value={values}>
                <ComponentToWrap {...values} {...props} />
            </TicketContext.Provider>
        );
    };

    const mapStateToProps = (state: RootState) => {
        const { constants } = state;
        const attachmentsConstants = {
            attachmentClassification: constants.attachmentClassification,
            attachmentType: constants.attachmentType,
        };
        return {
            tickets: state.tickets.byId,
            maxFileSize: state.constants.options.maxFileSize,
            attachmentsConstants,
        };
    };

    function mapDispatchToProps(dispatch) {
        return {
            uiActions: bindActionCreators(uiActions, dispatch),
            ticketStateActions: bindActionCreators(ticketActions, dispatch),
        };
    }

    const connector = connect(mapStateToProps, mapDispatchToProps);

    return UserWrapper(WorkflowWrapper(ConfirmWrapper(connector(WrappedComponent))));
};

export default TicketWrapper;
