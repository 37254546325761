declare global {
    interface Window {
        featureFlags: { [key: string]: boolean };
    }
}

//custom hook for funcitonal components
export const useGetFeatures = () => {
    const featureFlags =
        typeof window !== 'undefined' ? window.featureFlags : {};
    //console.log(featureFlags);
    return {
        ...getFeatures(featureFlags),
    };
};
// function for class components
export const getFeatures = (featureFlags: { [key: string]: boolean } = {}) => {
    return {
        FollowUp:
            checkFeatureFlag('ApplicationFollowUps', featureFlags) ?? true,
        UserPasswordSelfReset:
            checkFeatureFlag('UserPasswordSelfReset', featureFlags) ?? false,
        HideAuthor:
            checkFeatureFlag('HideAuthorInformation', featureFlags) ?? false,
        QueueStatusMenu:
            checkFeatureFlag('QueueStatusMenu', featureFlags) ?? false,
        VisRisk: checkFeatureFlag('VisRisk', featureFlags) ?? false,
        VisRiskCombinedProducts:
            checkFeatureFlag('VisRiskCombinedProducts', featureFlags) ?? false,
        VisRiskConsumerDuty:
            checkFeatureFlag('VisRiskConsumerDuty', featureFlags) ?? true,
        VisRiskFairValue:
            checkFeatureFlag('VisRiskFairValue', featureFlags) ?? false,
        VisRiskUsersMenu:
            checkFeatureFlag('VisRiskUsersMenu', featureFlags) ?? false,
        VisRiskEventsMenu:
            checkFeatureFlag('VisRiskEventsMenu', featureFlags) ?? false,
        VisRiskDelegates:
            checkFeatureFlag('VisRiskDelegates', featureFlags) ?? false,
        Vidende: checkFeatureFlag('Vidende', featureFlags) ?? false,
        VidendeUsersMenu:
            checkFeatureFlag('VidendeUsersMenu', featureFlags) ?? false,
        VidendeEventsMenu:
            checkFeatureFlag('VidendeEventsMenu', featureFlags) ?? false,
        UXFullScreenSingleQuestion:
            checkFeatureFlag('UXFullScreenSingleQuestion', featureFlags) ??
            false,
        VisRiskProductProgrammeAutoLaunch:
            checkFeatureFlag(
                'VisRiskProductProgrammeAutoLaunch',
                featureFlags,
            ) ?? false,
        Client1Specific:
            checkFeatureFlag(
                'Client1Specific',
                featureFlags,
            ) ?? false,            
        AllowAssignmentFilterCurrentParty:
            checkFeatureFlag(
                'AllowAssignmentFilterCurrentParty',
                featureFlags,
            ) ?? false,
    };
};

const checkFeatureFlag = (
    featureFlagKey: string,
    featureFlags: { [key: string]: boolean },
) => {
    if (!featureFlags || Object.keys(featureFlags).length === 0) {
        return null;
    }
    return featureFlagKey in featureFlags ? featureFlags[featureFlagKey] : null;
};
