import { useEffect, useState } from 'react';
import { Container, Dimmer, Header, Loader } from 'semantic-ui-react';
import EntityApi from '../../api/entityApi';
import { useAppSelector } from '../../actions/store';
import { ProductInformation } from '../../types';
import toastr from 'toastr';
import { useParams } from 'react-router-dom';
import ProductDistributorsTable from './ProductDistributorsTable';

const ProductDistributorsPage = ({}: {}) => {
    const [partyDistributors, setPartyDistributors] = useState([]);
    const [product, setProduct] = useState<ProductInformation>();
    const { id: productId } = useParams<{ id?: string }>();
    const [isLoading, setIsLoading] = useState(false);
    const partyId = useAppSelector(
        (state) => state.currentUser.details.partyId,
    );

    const fetchPartyDistributors = async () => {
        try {
            setIsLoading(true);
            const res = await EntityApi.getPartyProductsDistributorListing({
                where: {
                    and: [
                        { entityId: productId },
                        { executionOwnerPartyId: { neq: null } },
                    ],
                },
            });

            console.log(res);

            setPartyDistributors(
                res.filter(
                    (item) =>
                        item.party &&
                        item.clientStatusLabel !== 'Added in Error',
                ),
            );
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toastr.error(error);
        }
    };
    const fetchProduct = async () => {
        try {
            setIsLoading(true);
            const res = await EntityApi.getProductInformation(productId);
            setProduct(res);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toastr.error(error);
        }
    };
    useEffect(() => {
        fetchPartyDistributors();
        fetchProduct();
    }, []);

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">Distributor list for {product?.label}</Header>
            <ProductDistributorsTable distributors={partyDistributors} />
        </Container>
    );
};

export default ProductDistributorsPage;
