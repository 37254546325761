import update from 'immutability-helper/index';
import _ from 'lodash';

import * as ticketHelper from './helpers/ticketHelper';
import * as types from '../constants/actionTypes';
import { checkifEdited } from '../utils/React';
import { updateConversation } from './helpers/conversationHelpers';

export default function ticketsReducer(
    state = {
        followUpList: [],
        list: [],
        byId: {},
    },
    action,
) {
    switch (action.type) {
        case types.LOAD_TICKET: {
            const { id } = action;
            if (state.byId[id]) {
                return update(state, {
                    byId: {
                        [id]: {
                            loading: { $set: true },
                        },
                    },
                });
            }
            return state;
        }

        case types.LOAD_TICKETS_SUCCESS: {
            const { tickets, isPreview } = action;

            let newState = _.cloneDeep(state);

            _.forEach(tickets, (ticket) => {
                const { id } = ticket;
                const isAlreadyContainTicket = _.isObject(state.byId[id]);
                if (!isAlreadyContainTicket) {
                    newState = update(newState, {
                        byId: {
                            [id]: { $set: { ...ticket, isPreview } },
                        },
                    });
                }
            });
            return newState;
        }

        case types.LOAD_TICKET_SUCCESS: {
            const { ticket, isPreview } = action;

            // Adding codes to entries for use in display logic
            let newTicket;
            try {
                const entriesWithCodes = ticket.entries?.map((entry) => {
                    const field = entry.field;
                    let codes = undefined;
                    const selectedIds = String(entry.value)
                        .split(',')
                        .map(Number);
                    if (field.optionsValue && selectedIds) {
                        codes = selectedIds.map(
                            (id) =>
                                field.options.values.find(
                                    (opt) => opt.id === id,
                                )?.code,
                        );
                    }
                    if (codes) {
                        return {
                            ...entry,
                            codes,
                        };
                    }
                    return entry;
                });

                newTicket = entriesWithCodes
                    ? {
                          ...ticket,
                          entries: entriesWithCodes,
                      }
                    : ticket;
            } catch (error) {
                console.error('Error in ticketsReducer', error);
            }

            if (newTicket) {
                return update(state, {
                    byId: {
                        [ticket.id]: { $set: { ...newTicket, isPreview } },
                    },
                });
            }

            return update(state, {
                byId: {
                    [ticket.id]: { $set: { ...ticket, isPreview } },
                },
            });
        }

        case types.TICKET_ADD_TO_FOLLOW_UP_LIST: {
            let { id } = action;
            if (action.target) id = action.target.id;
            if (state.followUpList.includes(id)) return state;
            return update(state, {
                followUpList: { $push: [id] },
            });
        }
        case types.TICKETS_ADD_TO_FOLLOW_UP_LIST: {
            let { ids } = action;
            const toPush = _.without(ids, ...state.followUpList);
            if (toPush.length === 0) return state;
            return update(state, {
                followUpList: { $push: toPush },
            });
        }
        case types.TICKETS_REMOVE_FROM_FOLLOW_UP_LIST: {
            let { ids } = action;
            const withoutIds = _.without(state.followUpList, ...ids);
            return update(state, {
                followUpList: { $set: withoutIds },
            });
        }
        case types.TICKET_REMOVE_FROM_FOLLOW_UP_LIST: {
            let { id } = action;
            if (action.target) id = action.target.id;

            const index = _.indexOf(state.followUpList, id);
            if (index === -1) return state;

            return update(state, {
                followUpList: { $splice: [[index, 1]] },
            });
        }

        case `${types.SAVING_TICKET_RESOLUTION_SUCCESS}`: {
            const { result, resolution } = action;
            const { executionTicketId } = result;
            const currentTicket = _.cloneDeep(state.byId[executionTicketId]);
            const updatedticket = ticketHelper.updateTicketResolution(
                currentTicket,
                result,
                resolution,
            );
            return update(state, {
                byId: {
                    [executionTicketId]: { $set: { ...updatedticket } },
                },
            });
        }

        case `${types.SAVING_TICKET_REMOVE_RESOLUTION_SUCCESS}`: {
            const { statusId, ticketId } = action;
            const currentTicket = _.cloneDeep(state.byId[ticketId]);
            const updatedticket = ticketHelper.updateRemoveTicketResolution(
                currentTicket,
                statusId,
            );
            return update(state, {
                byId: {
                    [ticketId]: { $set: { ...updatedticket } },
                },
            });
        }

        case `${types.SAVING_TICKET_TRANSITION_SUCCESS}`: {
            const { result } = action;
            const { id } = result;
            let updatedTicket = update(state.byId[id], {
                saving: { $set: false },
                edited: { $set: false },
                currentStatusNodeId: { $set: result.currentStatusNodeId },
                statusNodeId: { $set: result.currentStatusNodeId },
                statusNodeColor: {
                    $set: result.currentStatusNode.status.statusType.colourId,
                },
                statusKey: {
                    $set: result.currentStatusNode.status.key,
                },
            });
            if (result.assignedPrincipal) {
                updatedTicket.assignedPrincipal = result.assignedPrincipal;
                updatedTicket.assignedPrincipalId = result.assignedPrincipal.id;
                updatedTicket.assignedPrincipalLabel = result.assignedPrincipal
                    .user
                    ? result.assignedPrincipal.user.label
                    : result.assignedPrincipal.userGroup.label;
                updatedTicket.assignedPrincipalType = result.assignedPrincipal
                    .user
                    ? 'user'
                    : 'usergroup';
            }
            return update(state, {
                byId: {
                    [id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.TICKET_UPDATE_ENTRY}`: {
            const {
                activityField,
                value,
                edited,
                ticket,
                outlier,
                excludeFromStatistics,
            } = action;
            const { id } = ticket;
            let updatedTicket = ticketHelper.updateFieldEntry(
                state.byId[id],
                activityField,
                value,
                edited,
                outlier,
                excludeFromStatistics,
            );
            const editedValue = checkifEdited(updatedTicket, state);

            return update(state, {
                byId: {
                    [id]: {
                        $set: {
                            ...editedValue,
                        },
                    },
                },
            });
        }

        case `${types.TICKET_ADD_ATTACHMENTS}`:
        case `${types.TICKET_ADD_EXISTING_ATTACHMENTS}`: {
            const { attachments, currentUser, ticket } = action;
            const { id } = ticket;
            let attachmentsResult = [];
            if (currentUser) {
                attachmentsResult = _.map(attachments, (attachment) => ({
                    ...attachment,
                    creator: currentUser,
                }));
            } else {
                attachmentsResult = attachments;
            }
            const currentTicket = state.byId[id];
            const updatedTicket = update(currentTicket, {
                attachments: currentTicket.attachments
                    ? { $push: attachmentsResult }
                    : { $set: attachmentsResult },
                attachmentsCount: {
                    $set: currentTicket.attachmentsCount + 1,
                },
            });

            return update(state, {
                byId: {
                    [id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.UPDATE_ATTACHMENT_SUCCESS}`: {
            const { attachment, userDetails, ticketId } = action;
            const currentTicket = state.byId[ticketId];
            if (currentTicket && currentTicket.attachments) {
                const newAttachmentsState = [...currentTicket.attachments];
                const indexOfAttachment = _.findIndex(newAttachmentsState, {
                    id: attachment.id,
                });
                if (indexOfAttachment !== -1) {
                    newAttachmentsState[indexOfAttachment] = {
                        ...attachment,
                        creator: userDetails,
                    };
                    const updatedTicket = update(currentTicket, {
                        attachments: { $set: newAttachmentsState },
                    });
                    return update(state, {
                        byId: {
                            [ticketId]: {
                                $set: {
                                    ...updatedTicket,
                                },
                            },
                        },
                    });
                }
            }
            return state;
        }

        case `${types.TICKET_DELETE_ATTACHMENT_SUCCESS}`: {
            const { attachment, ticket } = action;
            const { id } = ticket;
            const currentTicket = state.byId[id];
            const attachmentIndex = _.findIndex(currentTicket.attachments, {
                id: attachment.id,
            });
            let updatedTicket = update(currentTicket, {
                attachments: { $splice: [[attachmentIndex, 1]] },
                attachmentsCount: {
                    $set: currentTicket.attachmentsCount - 1,
                },
            });
            return update(state, {
                byId: {
                    [id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.SAVING_TICKET_SUCCESS}`: {
            const { result } = action;
            const { id } = result;
            const currentTicket = state.byId[id];
            let updatedTicket = update(currentTicket, {
                saving: { $set: false },
                edited: { $set: false },
            });
            const assignedPrincipal = result.assignedPrincipal;
            const assignedPrincipalLabel = _.get(assignedPrincipal, 'label');
            const assignedPrincipalType = _.get(assignedPrincipal, 'type');
            updatedTicket = {
                ...updatedTicket,
                ...result,
                assignedPrincipalLabel,
                assignedPrincipalType,
            };
            return update(state, {
                byId: {
                    [id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.SAVE_TICKET_ENTRIES_SUCCESS}`: {
            const { ticketId, ticket, result, currentUserDetails } = action;
            const id = ticket?.id || ticketId;
            const currentTicket = state.byId[id];

            const resultMerged = _.map(
                currentTicket.entries,
                (entry, index) => {
                    const modifiedBy =
                        currentTicket.entries.modifiedBy || currentUserDetails;
                    const updatedEntry = {
                        ...entry,
                        ...result[index],
                        edited: false,
                    };
                    if (entry.edited) {
                        updatedEntry.modifiedBy = modifiedBy;
                    }
                    return updatedEntry;
                },
            );

            const updatedTicket = update(currentTicket, {
                edited: { $set: false },
                saving: { $set: false },
                entries: { $set: resultMerged },
            });
            return update(state, {
                byId: {
                    [updatedTicket.id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.SAVE_TICKET_WORKFLOW_ENTRIES_SUCCESS}`: {
            const { ticket, result } = action;
            const { id } = ticket;
            const currentTicket = state.byId[id];
            const updatedTicket = update(currentTicket, {
                edited: { $set: false },
                saving: { $set: false },
                workflowEntries: { $set: result },
            });
            return update(state, {
                byId: {
                    [updatedTicket.id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.SAVING_TICKET_ENTRIES}`: {
            const { ticket } = action;
            const { id } = ticket;
            const currentTicket = state.byId[id];
            const updatedTicket = update(currentTicket, {
                saving: { $set: true },
            });
            return update(state, {
                byId: {
                    [updatedTicket.id]: {
                        $set: {
                            ...updatedTicket,
                        },
                    },
                },
            });
        }

        case `${types.SAVING_TICKETS_SUCCESS}`: {
            const { principal, response } = action;
            const tickets = response.results;
            const ticketsKeyBy = _.keyBy(tickets, 'id');
            let updatedState = _.cloneDeep(state);
            const { label, type } = { ...principal };
            _.forEach(ticketsKeyBy, (ticket) => {
                updatedState.byId[ticket.id] = {
                    ...updatedState.byId[ticket.id],
                    ...ticket,
                    assignedPrincipal: principal
                        ? {
                              ...ticket.assignedPrincipal,
                              [type]: principal,
                          }
                        : null,
                    assignedPrincipalLabel: label,
                    assignedPrincipalType: type,
                };
            });
            return updatedState;
        }

        case `${types.TICKET_ADD_DEADLINE_SUCCESS}`: {
            const { result } = action;
            const { executionTicketId } = result;
            const currentTicket = state.byId[executionTicketId];
            const newDeadlines = [...currentTicket.deadlines, result];
            const updatedTicket = update(currentTicket, {
                deadlines: { $set: newDeadlines },
            });
            const newState = update(state, {
                byId: {
                    [executionTicketId]: {
                        $set: updatedTicket,
                    },
                },
            });
            return newState;
        }

        case `${types.TICKET_REMOVE_DEADLINE_SUCCESS}`: {
            const { deadlineId, ticketId } = action;
            const currentTicket = state.byId[ticketId];
            const newDeadlines = _.filter(
                currentTicket.deadlines,
                (deadline) => {
                    return deadline.id !== deadlineId;
                },
            );
            const updatedTicket = update(currentTicket, {
                deadlines: { $set: newDeadlines },
            });
            const newState = update(state, {
                byId: {
                    [ticketId]: {
                        $set: updatedTicket,
                    },
                },
            });
            return newState;
        }

        default:
            return state;
    }
}
