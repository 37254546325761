import { FC } from 'react';
import FilterAndPaginate from '../common/FilterAndPaginate';
import { Table } from 'semantic-ui-react';
import SortableHeaderCell from '../common/SortableHeaderCell';
import Pagination from '../common/Pagination';
import ConfirmWrapper from '../common/ConfirmWrapper';
import { PartyProduct } from '../../types';
import { Link } from 'react-router-dom';
import FeatureFlag from '../common/FeatureFlag';

const ProductDistributorsTable: FC<any> = ({
    paginatedItems,
    handleSortChange,
    handleAddFilter,
    handlePaginationChange,
    paginationOptions,
    uiActions,
    confirmPopupActions,
    fetchLookUpValuesById,
    ...rest
}) => {
    return (
        <>
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="distributor"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Name
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="executionTicketSetlabel"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Product Name
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="productsource"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Source
                        </SortableHeaderCell>
                        <FeatureFlag
                            featureFlag="Client1Specific"
                            showHide={true}
                        >
                            <SortableHeaderCell
                                {...paginationOptions}
                                name="commissionPercentage"
                                handleSortChange={handleSortChange}
                                isSearchable={false}
                                isFilterable={false}
                            >
                                Commission
                            </SortableHeaderCell>
                            <SortableHeaderCell
                                {...paginationOptions}
                                name="feeAmount"
                                handleSortChange={handleSortChange}
                                isSearchable={false}
                                isFilterable={false}
                            >
                                Fee
                            </SortableHeaderCell>
                        </FeatureFlag>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="status"
                            handleSortChange={handleSortChange}
                            handleAddFilter={handleAddFilter}
                            isSearchable={true}
                            isFilterable={false}
                        >
                            Status
                        </SortableHeaderCell>
                        <SortableHeaderCell
                            {...paginationOptions}
                            name="lookUpValueCode"
                            width={1}
                        >
                            &nbsp;
                        </SortableHeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {paginatedItems.map((item: PartyProduct, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>
                                <Link to={item.clientLinkPath}>
                                    {item?.party?.label}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={item.clientLinkPath}>
                                    {item.executionTicketSetlabel}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>{item?.ownerParty?.label}</Table.Cell>
                            <FeatureFlag
                                featureFlag="Client1Specific"
                                showHide={true}
                            >
                                <Table.Cell>{item.commissionPercentage ? `${Number(item.commissionPercentage).toFixed(2)}%` : ''}</Table.Cell>
                                <Table.Cell>{item.feeAmount ? `${Number(item.feeAmount).toFixed(2)}` : ''}</Table.Cell>
                            </FeatureFlag>
                            <Table.Cell>{item.clientStatusLabel}</Table.Cell>
                            <Table.Cell
                                width={1}
                                style={{
                                    backgroundColor: item.clientStatusColor,
                                }}
                            />
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination
                activePage={paginationOptions.activePage}
                onPageChange={handlePaginationChange}
                totalPages={paginationOptions.totalPages}
            />
        </>
    );
};

function mapOptions(props) {
    return {
        items: props.distributors || [],
    };
}

export default FilterAndPaginate(mapOptions)(
    ConfirmWrapper(ProductDistributorsTable),
);
