import { FC } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { Field, FieldOptionValue } from '@brallum/vidende/src/types';

interface FieldOptionsDescriptionViewSidebarProps {
    field: Field;
}

const FieldOptionsDescriptionViewSidebar: FC<
    FieldOptionsDescriptionViewSidebarProps
> = ({ field }) => {
    const options = field?.options;
    return (
        <Segment basic>
            <Header as="h2">Available values for {field?.label}</Header>
            {Array.isArray(options) &&
                options?.map((option) => (
                    <Segment>
                        <Header as="h3">{option.label}</Header>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            <Header as="h4">{option.description}</Header>
                        </div>
                    </Segment>
                ))}
        </Segment>
    );
};

export default FieldOptionsDescriptionViewSidebar;
