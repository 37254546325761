import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';
import stringToBoolean from '../../../utils/convertStringToBoolean';

const CheckBoxBool = (props) => {
    const {
        label,
        value,
        defaultValue,
        onChange,
        disabled = false,
        required,
        editable = true,
        name,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
    } = props;

    if (!editable) {
        return <React.Fragment>{value.toString()}</React.Fragment>;
    }
    return (
        <Form.Field inline required={required}>
            <label>
                {label}
                {!props?.descriptionOnScreen && <FieldToolTip {...props} />}
                {isManufacturer && dataField && (
                    <Outlier
                        value={defaultValue || value}
                        name={name}
                        outlier={outlier}
                        excludeFromStatistics={excludeFromStatistics}
                        onChange={onChange}
                    />
                )}
            </label>
            {props?.descriptionOnScreen && props?.description && (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {props.description}
                </div>
            )}
            <Form.Group inline>
                <Form.Checkbox
                    checked={stringToBoolean(value)}
                    onChange={(e, { checked }) => {
                        onChange(e, { name, value: checked });
                    }}
                    disabled={disabled}
                />
            </Form.Group>
        </Form.Field>
    );
};

CheckBoxBool.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    defaultValue: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
    description: PropTypes.string,
};

CheckBoxBool.defaultProps = {
    value: '',
};

export default CheckBoxBool;
