import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Form, Header, Message } from 'semantic-ui-react';

import './FormSectionHeader.scss';
import { Field } from '../../../types';

interface Props extends Pick<Field, 'label' | 'description'> {
    options?: { style: 'Message' | 'Header'; args: any; hasDivider: boolean };
}

const FormSectionHeader = (props: Props) => {
    const { label, description, options } = props;

    const args = options?.args || {};

    const hasDivider = options?.hasDivider || false;

    if (options?.style === 'Message') {
        return (
            <Form.Field className="form-section-header" width={16}>
                <Message {...args}>
                    <Message.Header>{label}</Message.Header>
                    {description && (
                        <div style={{ whiteSpace: 'pre-line' }}>
                            <p>{description}</p>
                        </div>
                    )}
                </Message>
                <Divider hidden />
            </Form.Field>
        );
    }

    return (
        <Form.Field className="form-section-header" width={16}>
            {hasDivider && <Divider />}
            <Header size="large" {...args}>
                {label}
            </Header>
            {description && (
                <div style={{ whiteSpace: 'pre-line' }}>{description}</div>
            )}
        </Form.Field>
    );
};

FormSectionHeader.propTypes = {
    label: PropTypes.string,
};

export default FormSectionHeader;
