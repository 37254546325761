import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useAppSelector } from '../../actions/store';
import { loadEntities, loadEntityType } from '../../actions/entityActions';
import ProductList from './ProductList';
import ConfirmWrapper from './../common/ConfirmWrapper';
import {
    Button,
    Container,
    Dimmer,
    Divider,
    Loader,
    Segment,
} from 'semantic-ui-react';
import { addEntriesToEntities } from '../../utils/EntityUtils';
import PartyWrapper from '../party/Party';
import CheckPermission from '../common/CheckPermission';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

import * as permissionTypes from '../../constants/permissionTypes';
import { orderBy } from 'lodash';
import { useGetFeatures } from '@brallum/vidende/src/utils/Features';

const ProductsPage = ({ history }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const entities = useAppSelector((state) => state.entity.entities);
    const entityType = useAppSelector(
        (state) => state.entity.entityTypes.byId['PRODUCT'],
    );
    const party = useAppSelector((state) => state.currentUser.details.party);

    const fetchProducts = async () => {
        setIsLoading(true);
        await dispatch(
            loadEntities({
                where: { entityTypeId: 'PRODUCT' },
                include: ['entries', 'executions', 'entityType'],
            }),
        );
        if (!entityType) dispatch(loadEntityType('PRODUCT'));
        setIsLoading(false);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleShowBulkImportModal = () => {
        history.push(`/party/${party.id}/bulkImportEntities/product`);
    };

    const onEntityCreated = () => {
        fetchProducts();
    };

    const handleAddProduct = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.CREATE_ENTITY_MODAL,
                modalProps: {
                    partyId: party.id,
                    entityTypeId: 'PRODUCT',
                    onEntityCreated,
                },
            }),
        );
    };
    const handleLaunchGovernanceProgramme = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.LAUNCH_GOVERNANCE_PROGRAMME_MODAL,
                modalProps: {
                    mode: 'bulk',
                },
            }),
        );
    };

    const loading = !entities || !entityType || isLoading;

    const fields = orderBy(
        entityType?.fields?.filter(
            (field) => field.activityFieldTypeId !== 'Hidden',
        ),
        ['sort'],
    );

    const entitiesWithEntries = addEntriesToEntities(entities, fields);

    const { VisRiskConsumerDuty } = useGetFeatures();

    return (
        <Container fluid>
            <Dimmer active={loading} inverted>
                <Loader disabled={!loading} />
            </Dimmer>
            <Segment>
                <CheckPermission
                    structuralNodeId={party.structuralNodeId}
                    permissionType={permissionTypes.ADMIN}
                >
                    <div>
                        <Button primary onClick={handleShowBulkImportModal}>
                            Bulk import products
                        </Button>
                        <Button primary onClick={handleAddProduct}>
                            Add product
                        </Button>

                        {false && (
                            <Button
                                primary
                                onClick={handleLaunchGovernanceProgramme}
                            >
                                Governance Programme
                            </Button>
                        )}
                    </div>
                </CheckPermission>
            </Segment>
            {!loading && (
                <div style={{ overflowX: 'auto' }}>
                    <ProductList
                        //TODO: This needs to source from ProductInformation or PartyProducts
                        fetchProducts={fetchProducts}
                        entities={entitiesWithEntries || []}
                        displayedColumns={[
                            'PRODUCT_CLASS', // TODO: Remove this
                            'PRODUCT_REF',
                            'PRODUCT_CARRIER', // TODO: Remove this
                            'PRODUCT_CODE', // TODO: Remove this
                            'PRODUCT_GROUP',
                        ]}
                        hideActionButtons={true}
                    />
                </div>
            )}
        </Container>
    );
};

export default withRouter(ConfirmWrapper(ProductsPage));
