import * as _ from 'lodash';

export function getDefaultValues(ticket, currentStatusNode) {
    if (!ticket || !currentStatusNode) return {};
    const currentStatus = currentStatusNode.status;
    const statusResolutions = ticket.statusResolutions;
    const notes = _.get(ticket, 'activity.meta.notes');
    if (!notes) return {};
    const currentResolution = _.find(statusResolutions, {
        statusId: currentStatus.id,
    });
    if (!currentResolution) return {};
    const resolutionType =
        currentResolution.resolution.activityResolutionTypeId;
    const statusKey = currentStatus.key;
    const values = _.get(notes, `${statusKey}.${resolutionType}`);
    return values;
}

export default getDefaultValues;
