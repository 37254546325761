import {
    Button,
    Dimmer,
    Form,
    Loader,
    Modal,
    ModalActions,
} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { ProductInformation } from '../../types';
import { useEffect, useState } from 'react';
import PartyApi from '../../api/partyApi';
import toastr from 'toastr';
import MultiSelect from '../common/fields/MultiSelect';
import EntityApi from '../../api/entityApi';

interface Props {
    productInformation: ProductInformation;
}

const AddCoverHolderAndCoManufacturerModal = ({
    productInformation,
}: Props) => {
    const dispatch = useDispatch();
    const [distributors, setDistributors] = useState([]);
    const [isInitialLoad, setIsInitalLoad] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [coverholders, setCoverholders] = useState([]);
    const [selectedCoverholders, setSelectedCoverholders] = useState([]);
    const [coManufacturers, setCoManufacturers] = useState([]);
    const [selectedCoManufacturers, setSelectedCoManufacturers] = useState([]);

    const handleClose = () => {
        dispatch(uiActions.closeModal());
    };

    const fetchDistributors = async () => {
        try {
            const res = await PartyApi.getDistributorInfos({
                where: {
                    directDistributor: true,
                },
                order: 'label ASC',
            });
            setDistributors(res);
        } catch (error) {
            toastr.error(error);
            // handleClose();
        }
    };
    const fetchCoManufacturers = async () => {
        try {
            const res = await EntityApi.getEntityPartyRelationship({
                where: {
                    entityPartyRelationshipTypeId: 'COMANUFACTURER',
                    entityId: productInformation.id,
                },
            });
            setCoManufacturers(res);
            setSelectedCoManufacturers((prevState) => [
                ...prevState,
                ...res
                    .map((x) => {
                        if (!x.deleted) {
                            return x.partyId;
                        }
                    })
                    .filter((x) => x),
            ]);
        } catch (error) {
            toastr.error(error);
            // handleClose();
        }
    };

    const fetchCoverholders = async () => {
        try {
            const res = await EntityApi.getEntityPartyRelationship({
                where: {
                    entityPartyRelationshipTypeId: 'COVERHOLDER',
                    entityId: productInformation.id,
                },
            });
            setCoverholders(res);
            setSelectedCoverholders((prevState) => [
                ...prevState,
                ...res
                    .map((x) => {
                        if (!x.deleted) {
                            return x.partyId;
                        }
                    })
                    .filter((x) => x),
            ]);
        } catch (error) {
            toastr.error(error);
            // handleClose();
        }
    };

    const fetchInitialData = async () => {
        setIsInitalLoad(true);
        await fetchDistributors();
        await fetchCoManufacturers();
        await fetchCoverholders();
        setIsInitalLoad(false);
    };

    const handleChange = (e, { value, name }) => {
        if (name === 'coverholders') {
            setSelectedCoverholders(value);
        }
        if (name === 'coManufacturers') {
            setSelectedCoManufacturers(value);
        }
    };

    const handleSave = async () => {
        const postBody = [];
        postBody.push(
            ...selectedCoverholders.map((x) => {
                const matchedConverHolder = coverholders?.find(
                    (coverholder) =>
                        coverholder.entityId === productInformation.id &&
                        coverholder.partyId === x &&
                        coverholder.entityPartyRelationshipTypeId ===
                            'COVERHOLDER',
                );
                return {
                    id: matchedConverHolder?.id,
                    entityId: productInformation.id,
                    entityPartyRelationshipTypeId: 'COVERHOLDER',
                    partyId: x,
                    //productSummary: productInformation.summary,
                    deleted: false,
                };
            }),
        );
        postBody.push(
            ...selectedCoManufacturers.map((x) => {
                const matchedCoManufacturer = coManufacturers?.find(
                    (coManufacturer) =>
                        coManufacturer.entityId === productInformation.id &&
                        coManufacturer.partyId === x &&
                        coManufacturer.entityPartyRelationshipTypeId ===
                            'COMANUFACTURER',
                );
                return {
                    id: matchedCoManufacturer?.id,
                    entityId: productInformation.id,
                    entityPartyRelationshipTypeId: 'COMANUFACTURER',
                    partyId: x,
                    //productSummary: productInformation.summary,
                    deleted: false,
                };
            }),
        );

        try {
            setIsSaving(true);
            await EntityApi.updateEntityPartyRelationship(
                productInformation.id,
                postBody,
            );
            setIsSaving(false);
            handleClose();
        } catch (error) {
            setIsSaving(false);
            toastr.error(error);
        }
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    return (
        <Modal
            open={true}
            onClose={handleClose}
            closeOnDimmerClick={false}
            size="mini"
            title
        >
            <Dimmer active={isInitialLoad} inverted>
                <Loader disabled={!isInitialLoad} />
            </Dimmer>
            <Modal.Header>Add Coverholders and Co-Manufacturers</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <Form.Input
                            fluid
                            control={MultiSelect}
                            label="Coverholder"
                            name="coverholders"
                            options={distributors}
                            value={selectedCoverholders}
                            onChange={handleChange}
                            valueField="id"
                            labelField="label"
                            searchable={true}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            fluid
                            control={MultiSelect}
                            label="Co-Manufacturer"
                            name="coManufacturers"
                            options={distributors}
                            value={selectedCoManufacturers}
                            onChange={handleChange}
                            valueField="id"
                            labelField="label"
                            searchable={true}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <ModalActions>
                <Button primary loading={isSaving} onClick={handleSave}>
                    Save
                </Button>
                <Button secondary loading={isSaving} onClick={handleClose}>
                    Close
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default AddCoverHolderAndCoManufacturerModal;
