import { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Header,
    Loader,
    Segment,
    Select,
} from 'semantic-ui-react';
import ServerConfigurationApi, {
    LookUpType,
    LookUpValue,
} from '../../api/serverConfigurationApi';
import toastr from 'toastr';
import LookUpValuesTable from './LookUpValuesTable';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

const LookUpConfigurationPage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [lookUpTypes, setLookUpTypes] = useState<LookUpType[]>([]);
    const [selectedLookUpTypeId, setSelectedLookUpTypeId] = useState<any>();
    const [lookUpValues, setLookUpValues] = useState<LookUpValue[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchLookUpTypes();
    }, []);

    const fetchLookUpTypes = async () => {
        setIsLoading(true);
        try {
            const lookUpData = await ServerConfigurationApi.getLookUpTypes();
            setLookUpTypes(lookUpData);
            setIsLoading(false);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    const fetchLookUpValuesById = async (id) => {
        setIsLoading(true);
        try {
            const res = await ServerConfigurationApi.getLookUpValuesById(id);
            setIsLoading(false);
            setLookUpValues(res);
        } catch (error) {
            toastr.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        selectedLookUpTypeId && fetchLookUpValuesById(selectedLookUpTypeId);
    }, [selectedLookUpTypeId]);

    const lookUpTypesOptions = lookUpTypes.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.lookUpTypeLabel,
    }));

    const handleAddLookUpType = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_LOOK_UP_TYPE_MODAL,
                modalProps: {
                    onSuccess: fetchLookUpTypes,
                },
            }),
        );
    };
    const handleEditLookUpType = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_LOOK_UP_TYPE_MODAL,
                modalProps: {
                    item: lookUpTypes.find(
                        (x) => x.id === selectedLookUpTypeId,
                    ),
                    onSuccess: fetchLookUpTypes,
                },
            }),
        );
    };

    const handleAddLookUpValue = () => {
        dispatch(
            uiActions.showModal({
                modalType: MODAL_COMPONENTS.SAVE_LOOK_UP_VALUE_MODAL,
                modalProps: {
                    onSuccess: () =>
                        fetchLookUpValuesById(selectedLookUpTypeId),
                    item: { lookUpTypeId: selectedLookUpTypeId },
                },
            }),
        );
    };

    return (
        <Container>
            <Dimmer active={isLoading} inverted>
                <Loader disabled={!isLoading} />
            </Dimmer>
            <Header as="h2">Look Up Configuration</Header>
            <Segment>
                <Header as="h3">Look Up Type</Header>
                <Form>
                    <Form.Group>
                        <Form.Input width={4}>
                            <Select
                                value={selectedLookUpTypeId}
                                fluid
                                options={lookUpTypesOptions}
                                onChange={(_, { value }) => {
                                    setSelectedLookUpTypeId(value);
                                }}
                            />
                        </Form.Input>
                        <Form.Input>
                            <Button onClick={handleAddLookUpType}>Add</Button>
                        </Form.Input>
                        <Form.Input>
                            <Button onClick={handleEditLookUpType}>Edit</Button>
                        </Form.Input>
                    </Form.Group>
                </Form>
            </Segment>
            <Segment>
                <Header as="h3">Look Up Values</Header>
                <Button
                    onClick={handleAddLookUpValue}
                    primary
                    disabled={!selectedLookUpTypeId}
                >
                    Add new Look Up Value
                </Button>
                <LookUpValuesTable
                    // @ts-ignore
                    fetchLookUpValuesById={fetchLookUpValuesById}
                    lookUpValues={lookUpValues}
                />
            </Segment>
        </Container>
    );
};

export default LookUpConfigurationPage;
