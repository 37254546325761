import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Checkbox, Table, Label } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import executionPlanApi from '../../../api/executionPlanApi';
import ticketApi from '../../../api/ticketApi';
import { ExecutionPlan, ExecutionTicketExtended } from '../../../types';
import * as uiActions from '@brallum/vidende/src/actions/uiActions';
import FormField from '../../common/FormField';
import toastr from 'toastr';

interface CreateActionModalProps {
    ticket: ExecutionTicketExtended;
}

interface ActionFormData {
    title: string;
    executionPlanId: string;
    assignEntries: boolean;
    selectedEntries: number[];
}

export const CreateActionModal: React.FC<CreateActionModalProps> = ({
    ticket,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formData, setFormData] = useState<ActionFormData>({
        title: '',
        executionPlanId: '',
        assignEntries: false,
        selectedEntries: [],
    });
    const [executionPlans, setExecutionPlans] = useState<ExecutionPlan[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchExecutionPlans = async () => {
            setIsLoading(true);
            try {
                const plans =
                    await executionPlanApi.getExecutionPlansByCategory(
                        'Action',
                    );
                setExecutionPlans(plans);
            } catch (error) {
                console.error('Failed to fetch execution plans:', error);
                toastr.error('Failed to fetch execution plans');
            } finally {
                setIsLoading(false);
            }
        };

        fetchExecutionPlans();
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        { name, value, checked }: any,
    ) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value !== undefined ? value : checked,
        }));
    };

    const handleEntryToggle = (entryId: number) => {
        setFormData((prev) => ({
            ...prev,
            selectedEntries: prev.selectedEntries.includes(entryId)
                ? prev.selectedEntries.filter((id) => id !== entryId)
                : [...prev.selectedEntries, entryId],
        }));
    };

    const isFormValid =
        formData.title &&
        formData.executionPlanId &&
        (!formData.assignEntries || formData.selectedEntries.length > 0);

    let filteredTicketEntries =
        ticket.entries?.filter((entry) => !!entry.current) || [];

    filteredTicketEntries = filteredTicketEntries.sort(
        (a, b) =>
            a.field.sort - b.field.sort ||
            a.field.label.localeCompare(b.field.label),
    );

    const handleCloseModal = () => {
        dispatch(uiActions.closeModal());
    };

    const handleCreateAction = async () => {
        setIsLoading(true);
        try {
            const response = await ticketApi.createActionTicket(ticket.id, {
                fieldEntryIds: formData.selectedEntries,
                executionPlanId: parseInt(formData.executionPlanId),
                label: formData.title,
            });

            toastr.success('Action ticket created successfully');
            handleCloseModal();
            history.push(`/ticket/${response.id}`);
        } catch (error) {
            console.error('Failed to create action ticket:', error);
            toastr.error('Failed to create action ticket');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal size="small" open onClose={handleCloseModal}>
            <Modal.Header>New Action</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        label="Action title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                    <Form.Select
                        label="Action Type"
                        name="executionPlanId"
                        options={executionPlans.map((plan) => ({
                            key: plan.id,
                            text: plan.label,
                            value: plan.id,
                        }))}
                        value={formData.executionPlanId}
                        onChange={handleChange}
                        loading={isLoading}
                        required
                    />
                    <Form.Field>
                        <Checkbox
                            label="Assign entries to action?"
                            name="assignEntries"
                            checked={formData.assignEntries}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    {formData.assignEntries && (
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Field</Table.HeaderCell>
                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {filteredTicketEntries.map((entry) => (
                                    <Table.Row key={entry.id}>
                                        <Table.Cell>
                                            {entry.field.label}
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <FormField
                                                field={{
                                                    type: entry.field
                                                        .activityFieldTypeId,
                                                }}
                                                value={entry.value}
                                                editable={false}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Checkbox
                                                checked={formData.selectedEntries.includes(
                                                    entry.id,
                                                )}
                                                onChange={() =>
                                                    handleEntryToggle(entry.id)
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                    positive
                    onClick={handleCreateAction}
                    disabled={!isFormValid || isLoading}
                    loading={isLoading}
                >
                    Create Action
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateActionModal;
