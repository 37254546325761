import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Icon, Popup, Select as SSelect } from 'semantic-ui-react';
import * as _ from 'lodash';

import FieldErrors from './FieldErrors';
import { mapToOptions } from '../../../utils/React';
import FieldToolTip from './FieldToolTip';
import Outlier from './Outlier';
import FieldDescriptionView from './FieldDescriptionView';

const SearchableSelect = (props) => {
    const {
        label,
        options,
        onChange,
        value,
        name,
        disabled,
        editable = true,
        required,
        errors,
        onFocus,
        onBlur,
        width,
        className,
        loading,
        sortItemsAlphabetically = false,
        clearable = false,
        compact,
        fluid,
        open,
        error,
        outlier,
        excludeFromStatistics,
        dataField,
        isManufacturer,
        valueField,
        labelField,
        handleOpenSideBar,
    } = props;
    const fieldErrors = errors ? errors[name] : undefined;

    const displayValue =
        Array.isArray(options) &&
        options.find((option) => option.value == value)?.label;

    if (!editable) {
        return <React.Fragment>{displayValue ?? value}</React.Fragment>;
    }

    const optionsHaveDescription = options.some((option) => option.description);

    const mappedOptions = mapToOptions(
        options,
        labelField,
        sortItemsAlphabetically,
        valueField,
    );
    return (
        <React.Fragment>
            <Form.Field required={required} width={width} error={error}>
                <label>
                    {label}
                    {!props?.descriptionOnScreen && <FieldToolTip {...props} />}
                    {isManufacturer && dataField && (
                        <Outlier
                            value={value}
                            name={name}
                            outlier={outlier}
                            excludeFromStatistics={excludeFromStatistics}
                            onChange={onChange}
                        />
                    )}
                    {handleOpenSideBar && optionsHaveDescription && (
                        <FieldDescriptionView
                            handleOpenSideBar={handleOpenSideBar}
                        />
                    )}
                </label>
                {props?.descriptionOnScreen && props?.description && (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {props.description}
                    </div>
                )}
                <Form.Field
                    control={SSelect}
                    search
                    options={mappedOptions}
                    placeholder={label}
                    value={value}
                    name={name}
                    disabled={disabled || !editable}
                    error={!!fieldErrors}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={className}
                    loading={loading}
                    selectOnBlur={false}
                    clearable={clearable}
                    compact={compact}
                    fluid={fluid}
                    open={open}
                />
            </Form.Field>
            {!_.isEmpty && <FieldErrors errors={fieldErrors} />}
        </React.Fragment>
    );
};

SearchableSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    errors: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    loading: PropTypes.bool,
    sortItemsAlphabetically: PropTypes.bool,
    clearable: PropTypes.bool,
    compact: PropTypes.bool,
    fluid: PropTypes.bool,
    open: PropTypes.bool,
    dataField: PropTypes.bool,
    outlier: PropTypes.bool,
    excludeFromStatistics: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    descriptionOnScreen: PropTypes.bool,
    valueField: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SearchableSelect;
